import { Box, TextField,Button ,FormControl} from "@material-ui/core";
import { ContactSupportOutlined, Label } from "@material-ui/icons";
import {React, useEffect} from "react";
function isShowReadMore(elm) {
    const scrollHeight = elm.scrollHeight;
    const maxHeight = elm.clientHeight;
    return scrollHeight > maxHeight;
}
function showAllData(elm){
   
    if(elm.classList.contains('readmore')){
        elm.parentElement.querySelector('.readmore-content').style.display = 'block';
        elm.style.display='none';
    }else if(elm.classList.contains('readmore-box')){
        elm.querySelector('.readmore-content').style.display = 'block';
        elm.querySelector('.readmore').style.display = 'none';
    }
    else{
    }
}

export const ResetReadMoreButtons=()=> {
    const elements = document.querySelectorAll('.readmore-content');
    elements.forEach((e) => {
        e.style = null;
        const readmoreElement = e.parentElement.querySelector('.readmore');
        readmoreElement.style.display = 'block';
    });
}

export const ShowReadMoreButtons=()=> {
      const elements = document.querySelectorAll('.readmore-content');
      elements.forEach((e) => {
        const readmoreElement = e.parentElement.querySelector('.readmore');
        if (isShowReadMore(e)) {
          readmoreElement.style.display = 'block';
        } else {
          readmoreElement.style.display = 'none';
        }
      });
}

const ContentReadmore=({data,readMoreText})=>{
    //const [isReadMore, setReadMoree] = React.useState(false);
    const handleExpandClick=(elm)=>{
        if(elm.target!=null){
            showAllData(elm.target.parentElement);
        }else{
            //showAllData(elm);
        }
    }
    return (
        <div className="readmore-box">
            <Box className="readmore-content"            
            sx={{
                whiteSpace: "pre-wrap",
            }}                                
            >
            {data}
            </Box>
            <Button className="readmore"   
                variant="contained"
                onClick={handleExpandClick}
                disableElevation={true}
                style={{background: "white", border: '1px solid ', borderColor: '#AEAEAE',
                paddingTop: "10px",
                paddingBottom: "10px",
                fontSize: "16px" }}
                >
                {readMoreText}
            </Button>
	</div>
 );
}
export default ContentReadmore;