import React, {useState} from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@material-ui/core";
import { /*NavLink,*/ useHistory } from "react-router-dom";
import { red } from "@material-ui/core/colors";
import ToretaApi from "../ToretaApi";
import { ShopDetail, FinishInfo, ShopInfo, YoyakuBody } from "../common/interface";
import { GreenButton } from "../Components";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        media: {
            height: 0,
            paddingTop: "56.25%", // 16:9
        },

        expand: {
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest,
            }),
        },
        required: {
            color: "red",
        },
        expandOpen: {
            transform: "rotate(180deg)",
        },
        avatar: {
            backgroundColor: red[500],
        },
        alignItemsAndJustifyContent: {
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
        },
        tableTitle: {
            fontWeight: "bolder",
        },
    })
);

type Props = {
    // initValue: YoyakuBody;
    // className: any;
    // reservationNo: number | null;
    // onNext: (data: YoyakuBody) => void;
};

const Step1: React.FunctionComponent<Props> = (props) => {
    const toretaApi = ToretaApi();
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { transformStattTime, GetFinishReserve, GetShopOther, GetShopDetail, DisplayCustomername, ScrollTop, transformDateByStattTime, GetDebitAmount } = ToretaApi();
    const shop: ShopDetail = useSelector((state: any) => state.shop);
    const shopinfo: ShopInfo = useSelector((state: any) => state.shopinfo);
    const [reserveData, setReserveDat] = React.useState<FinishInfo | null>(null);
    const [checkAddress, setAddress] = useState(false);
    const [fee, setFee] = React.useState<number | null>(null);
    var seatTypeLanguage: { [id: string] : string; } = {};
    const setDisplaySeats=()=>{
        seatTypeLanguage["none"]="RESERVE_INFO.ITEM_NO_PREFERENCE";
        seatTypeLanguage["counter"]="RESERVE_INFO.ITEM_COUNTER_SEAT";
        seatTypeLanguage["private"]="RESERVE_INFO.ITEM_PRIVATE_ROOM";
        seatTypeLanguage["table"]="RESERVE_INFO.ITEM_TABLE_SEAT";
        seatTypeLanguage["outdoor_seat"]="RESERVE_INFO.ITEM_OUTDOOR_SEAT";
    }
    React.useEffect(() => {
        ScrollTop();
        GetFinishReserve().then((data) => {
            setReserveDat(data);
            setTimeout(() => {
                if(!reserveData || !reserveData.reservationNo){
                    setReserveDat(data);
                }
            }, 1000); 
        });
        // eslint-disable-next-line
    }, []);
    React.useEffect(() => {
        if (!shop.public_key) {
            GetShopDetail();
        }
        // eslint-disable-next-line
    }, []);
    React.useEffect(() => {
        if (!shopinfo.courses) {
            GetShopOther();
        }
        if(shop.address!==null&&shop.address!==""){
            setAddress(true);
        }else{
            setAddress(false);
        }
        // eslint-disable-next-line
    }, []);
    React.useEffect(() => {
        GetDebitAmount(reserveData as YoyakuBody).then((fee) => {
            setFee(fee);
        });
    }, [reserveData]);
    interface Map {
        [key: string]: any;
        [index: number]: any;
    }
    const dicCurrency:Map={
        "SGD":"S$",
        "TWD":"NT$",
        "MYR":"RM",
        "THB":"฿",
        "JPY":"¥",
    }
    const getCurrency = (key: string) =>{
        var c= Object.keys(dicCurrency).filter((val,ind) => {return val==key;});
        if(c.length>0){
            return dicCurrency[key];
        }
        return key;
    }
    const formatter = new Intl.NumberFormat('ja-JP', {
        //style: 'currency',
        currency: 'JPY',
      });
    const formatterEn = new Intl.NumberFormat('en-US', {
        currency: 'USD',
    });
    const getCurrencyAmt = (amt: number) =>{
        
        if(shop.currency=="JPY"){
            return formatter.format(Math.trunc(amt));
        }
        return formatterEn.format(amt);
    }
    
    const getDisplaySeats=(key:any)=>{
        setDisplaySeats();
        const defV = shopinfo.tables?.find((a) => a.key_name === key)?.display_name;
        //console.log("getDisplySeats");
        try{ 
        var lang = t(seatTypeLanguage[key]);
        if(lang!=undefined && lang!=null && lang.length>0) 
        {
            return lang;
        }
        return defV;
        }catch{
            return defV;
        }
    }

    if (!reserveData) {
        return <></>;
    }
    if (!reserveData.reservationNo) {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <br />
                        <Typography variant="body1" component="h2" gutterBottom>
                            <Box fontWeight="fontWeightBold" m={0} className={classes.alignItemsAndJustifyContent}>
                                {t("FINISH.TIMEOUT")}
                            </Box>
                        </Typography>

                        <br />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <br />
                        <GreenButton
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                history.push("/");
                            }}
                        >
                            {t("FINISH.BUTTON_TEXT")}
                        </GreenButton>
                    </Grid>
                </Grid>
            </>
        );
    }

    return (
        <>
            <Box textAlign="center" my={2}>
                <Typography variant="h4" component="h2" gutterBottom>
                    <Box fontWeight="fontWeightBold" className={classes.alignItemsAndJustifyContent}>
                        {t("FINISH.RESERVE_NO")}:{reserveData.reservationNo}
                    </Box>
                </Typography>
            </Box>
            <Box my={2}>
                <Typography variant="body1" component="h2" gutterBottom>
                    <Box fontWeight="fontWeightBold" className={classes.alignItemsAndJustifyContent}>
                        {t("FINISH.MESSAGE_1")}
                        <br />
                        {t("FINISH.MESSAGE_2")}
                    </Box>
                </Typography>
            </Box>
            <Box my={2}>
                <Typography variant="body1" component="h2" gutterBottom>
                    <Box fontWeight="fontWeightBold">
                        <span>{t("RESERVE_CONFIRM")}</span>
                    </Box>
                </Typography>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("RESTAURANT_NAME")}</TableCell>
                            <TableCell align="left">{shop.name}</TableCell>
                        </TableRow>
                        { checkAddress ?(
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("ADDRESS")}</TableCell>
                            <TableCell align="left">{shop.address}</TableCell>
                        </TableRow>
                        ):(
                        ""
                        )}
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("PARTY_SIZE")}</TableCell>
                            <TableCell align="left">
                                {reserveData.seats}
                                {t("PEOPLE")}
                            </TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("DATE_TIME")}</TableCell>
                            <TableCell align="left">
                                {transformDateByStattTime(reserveData.target_date as string, reserveData.start_time as number)}{" "}
                                {transformStattTime(reserveData.start_time as number)} {t("IN")}
                                {reserveData.end_time ? " " + transformStattTime(reserveData.end_time as number) + " " + t("OUT") : ""}
                            </TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("TABLE_CAETGORY")}</TableCell>
                            <TableCell align="left">{getDisplaySeats(reserveData.table_category)}</TableCell>
                        </TableRow>
                        {reserveData.courses ? (
                            <>
                                <TableRow hover>
                                    <TableCell className={classes.tableTitle}>{t("RESERVE_INFO.SELECT_COURSE")}</TableCell>
                                    <TableCell align="left">
                                        {" "}
                                        {reserveData.courses?.map((course) => {
                                            const menu = shopinfo.courses?.find((a) => a.id === course.id);
                                            if (!menu) {
                                                return <></>;
                                            } else {
                                                return (
                                                    <>
                                                        {menu?.name}
                                                        {"  "}
                                                        {t("RESERVE_INFO.IN_TAX", {
                                                            //price: menu?.price,
                                                            price: (menu.price ? (" " +(shop.company_country!="jp"?(shop.currency + menu.price):getCurrency(shop.currency)+" "+ getCurrencyAmt(parseFloat(menu.price)))) : null),
                                                            seats: course.count,
                                                        })}
                                                        <br />
                                                    </>
                                                );
                                            }
                                        })}
                                    </TableCell>
                                </TableRow>
                            </>
                        ) : (
                            <></>
                        )}
                        {/* <TableRow>
                                <TableCell>{t("COURSE")}</TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow> */}
                        {fee !== null &&  fee > 0 &&
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("FINISH.DEPOSIT")}</TableCell>
                            <TableCell align="left">
                                {shop.company_country!="jp"
                                    ? (fee + " " + shop.currency)
                                    : (getCurrency(shop.currency) + " " + getCurrencyAmt(fee))
                                }
                            </TableCell>
                        </TableRow>
                        }
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("REQUEST")}</TableCell>
                            <TableCell align="left">{reserveData.note}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box my={2}>
                <Typography variant="body1" component="h2" gutterBottom>
                    <Box fontWeight="fontWeightBold" m={0}>
                        <span>{t("CONTACT_INFO")}</span>
                    </Box>
                </Typography>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("NAME")}</TableCell>
                            <TableCell align="left">{DisplayCustomername(reserveData)}</TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("MOBILE")}</TableCell>
                            <TableCell align="left">{reserveData.phoneview ? reserveData.phoneview : reserveData.phone}</TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("EMAIL")}</TableCell>
                            <TableCell align="left">{reserveData.email}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box textAlign="center" mt={5} mb={2}>
                {shop.message_success
                    .split("\n")
                    .map(function (item, key) {
                        return (
                            <span key={key}>
                                {key>0?(<br />):("")}
                                {item}
                                
                            </span>
                        );
                    })
                }
            </Box>
            <Box textAlign="center" mt={5} mb={2}>
                <GreenButton
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        history.push("/");
                    }}
                >
                    {t("FINISH.BUTTON_TEXT")}
                </GreenButton>
            </Box>
        </>
    );
};
export default Step1;
