import React from "react";
import { DatePicker /*KeyboardDatePicker*/ } from "@material-ui/pickers";
import { makeStyles, createStyles /*Theme,withStyles*/ } from "@material-ui/core/styles";
import moment from "moment";
import clsx from "clsx";
// import format from "date-fns/format";
// import startOfWeek from "date-fns/startOfWeek";
// import endOfWeek from "date-fns/endOfWeek";
import { IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) =>
    createStyles({
        base: {
            color: "black",
        },

        saturday: {
            color: "green",
        },
        sunday: {
            color: "red",
        },
        disabled: {
            color: "#d0cbcb",
        },
        selected: {
            color: "#fff",
            fontWeight: 500,
            backgroundColor: "#1976d2",
            "&:hover": {
                fontWeight: 500,
                backgroundColor: "#1976d2",
            },
        },
    })
);
var fistLoad=true;
const SelfDatePicker = ({disabled = false, label = "", value, onChange, enableDays = [], ...props }) => {
    const classes = useStyles();
    const momentdate = value ? moment(value) : moment();
    const inputdateString = momentdate.format("YYYY-MM-DD");
    // console.log("test", momentdate);
    // const [selectedDate, setDate] = React.useState(momentdate);
    const [selectedDate, setDate] = React.useState(momentdate);

    const [inputValue, setInputValue] = React.useState(inputdateString);
    
    React.useEffect(() => {        
            if(fistLoad){
                setDate(null);
                setInputValue(null); 
                fistLoad=false;
            }else{
                setDate(momentdate);
                setInputValue(inputdateString); 
            }   
                
        // eslint-disable-next-line
    }, [value]);

    function disableRule(date) {
        if (!enableDays) {
            return false;
        }
        return !enableDays.includes(date.format("YYYY-MM-DD"));
    }
    const onDateChange = (date) => {
        const value = date.format("YYYY-MM-DD");
        setDate(date);
        setInputValue(value);
        onChange(value);
    };
    const dateFormatter = (str) => {
        // console.log(str);
        return str;
    };
    return (
        <DatePicker
            disabled={disabled}
            disableToolbar
            fullWidth
            animateYearScrolling
            label={label}
            autoOk={true}
            showTodayButton={false}
            value={selectedDate}
            inputProps={{ readOnly: true }}
            format="YYYY-MM-DD"
            inputValue={inputValue}
            onChange={onDateChange}
            shouldDisableDate={disableRule}
            rifmFormatter={dateFormatter}
            helperText={null}
            renderDay={(day, selected, dayInCurrentMonth) => {
                // console.log(day, selected, dayInCurrentMonth);

                return (
                    <IconButton
                        className={clsx({
                            [classes.base]: true,
                            [classes.selected]: day.isSame(selected),
                            [classes.sunday]: day.day() === 0,
                            [classes.saturday]: day.day() === 6,
                            [classes.disabled]: disableRule(day) || !dayInCurrentMonth,
                        })}
                        style={{ height: "36px" }}
                    >
                        <Typography variant="body2" component="p">
                            {day.format("DD")}
                        </Typography>
                    </IconButton>
                );
                // return <Day {...DayProps} />;
            }}
            {...props}
        />
    );
};
export default SelfDatePicker;
