import React, { useRef, useImperativeHandle } from "react";
const StripeInput = ({ component: Component, inputRef, ...props }) => {
    const elementRef = useRef();
    useImperativeHandle(inputRef, () => ({
        focus: () => elementRef.current.focus,
    }));
    return <Component onReady={(element) => (elementRef.current = element)} {...props} />;
};
const ELEMENT_OPTIONS = {
    style: {
        base: {
            fontSize: "18px",
            color: "#424770",
            letterSpacing: "0.025em",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#9e2146",
        },
    },
};

export { ELEMENT_OPTIONS };
export default StripeInput;
