import React from "react";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import SGVersion from "./sg";
import TWVersion from "./tw";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& h3": {
                color: "blue",
            },
            "& h4": {
                fontWeight: "bold",
            },
            "& ul": {
                listStyle: "none",
                margin: 0,
                padding: 0,
            },
            "& ul li": {
                textIndent: "-1em",
                marginLeft: "1em",
                marginBottom: "10px",
            },
        },
        textRight: {
            textAlign: "right",
        },
    })
);

export default function ShopPage() {
    const classes = useStyles();
    const { t } = useTranslation();
    let UsedComponent = SGVersion;
    const params: any = useParams();
    const { country } = params;
    if (country === "sg") {
        UsedComponent = SGVersion;
    } else if (country === "tw") {
        UsedComponent = TWVersion;
    }
    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography variant="h6" component="h2" gutterBottom>
                    <Box fontWeight="fontWeightBold" m={1}>
                        | {t("TERMS_OF_SERVICE")}
                    </Box>
                </Typography>
                <Box p={1} />
                <Typography variant="body1" component="p" gutterBottom>
                    <UsedComponent></UsedComponent>
                </Typography>
            </CardContent>
        </Card>
    );
}
