
import loading from "./loading";
import shop from "./shop"
import shopinfo from "./shopinfo"
import lang from "./lang"
import { combineReducers } from "redux";

export default combineReducers({
    loading,
    shop,
    shopinfo,
    lang
});
