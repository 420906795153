import React from "react";
import { useSelector } from "react-redux";
import { /*NavLink,*/ useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Card, CardContent, MobileStepper, Stepper, Step, StepLabel } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

// import ToretaApi from "../../ToretaApi";
import { ShopDetail, YoyakuBody, PersonalInfo, YoyakuCourse } from "../../common/interface";
import ToretaApi from "../../ToretaApi";
import Step1 from "./Step1";

import Step2 from "./Step2";

import Step3 from "./Step3";

import Step4 from "./Step4";

import "./override.css"

// import Step5 from "./Step5";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        overrides: {
            MuiSelect: {
                Select:{
                    whiteSpace: "normal",
                    wordBreak: "break-all"
                }               
            }
        },
        root: {
            padding: theme.spacing(3),
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(0.5),
            },
            paddingTop: 0,
        },
        steppeRoot: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
        },
        media: {
            height: 0,
            paddingTop: "56.25%", // 16:9
        },
        expand: {
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest,
            }),
        },
        required: {
            color: "red",
        },
        expandOpen: {
            transform: "rotate(180deg)",
        },
        avatar: {
            backgroundColor: red[500],
        },
        hide: {
            display: "none",
        },
        mobileStepper: {
            minWidth: "100%",
            flexGrow: 1,
        },
    })
);
const initValue: YoyakuBody = {
    adults: 0,
    children: 0,
    target_date: null,
    start_time: null,
    seats: 0,
    table_category: null,
    last_name: "",
    first_name: "",
    country_code: "",
    phone: "",
    email: "",
    courses: [],
    note: "",
    email_template: "",
    language:"",
    optin_dm: true,
};

const judgeMobile = () => window.innerWidth < 768;
export default function RecipeReviewCard() {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const shop: ShopDetail = useSelector((state: any) => state.shop);
    const toretaApi = ToretaApi();
    // const appContext = UseAppContext();
    const [mobile, setMobile] = React.useState(judgeMobile());
    const [form, setForm] = React.useState<YoyakuBody>({
        ...initValue,
        courses: [
            {
                id: "",
                count: 0,
            },
        ] as YoyakuCourse[],
    });
    // console.log("test", form);
    // const [seatInfos, setSeatInfos] = React.useState<ToretaReservationsDaysResponse>({});
    const [activeStep, setActiveStep] = React.useState(0);
    // const [reservationNo, setReservationNo] = React.useState<number | null>(null);
    // const [skipped, setSkipped] = React.useState(new Set<number>());
    React.useEffect(() => {
        const onResize = () => {
            setMobile(judgeMobile());
        };

        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);
    const steps = [t("RESERVE_INFO.EXPLAIN"), t("PERSONAL_INFO.EXPLAIN"), t("RESERVE_CONFIRMATION.BUTTON_TEXT")];
    steps.push(t("PAYMENT.CRESIT"));

    if (!shop.public_key) {
        history.replace("/");
        return <></>;
    }
    const backStep1 = () => {
        setActiveStep(0);
    };
    const backStep2 = () => {
        setActiveStep(1);
    };
    const goStep2 = (newdata: YoyakuBody): void => {
        setForm(newdata);
        setActiveStep(1);
    };
    const goStep3 = (newdata: PersonalInfo): void => {
        setForm({ ...form, ...newdata });
        setActiveStep(activeStep + 1);
    };
    const goStep4 = (newdata: PersonalInfo): void => {
        const newform = { ...form, ...newdata };
        toretaApi.AddReserveSripe(
            newform,
            "",
            () => {
                setForm(newform);
                setActiveStep(activeStep + 1);
            },
            null
        );
    };

    let currentStepComponent = null;
    if (activeStep === 0) {
        currentStepComponent = <Step1 initValue={form} onNext={goStep2} className={clsx(activeStep !== 0 && classes.hide)}></Step1>;
    } else if (activeStep === 1) {
        currentStepComponent = <Step2 initValue={form} onNext={goStep3} className={clsx(activeStep !== 1 && classes.hide)}></Step2>;
    } else if (activeStep === 2) {
        currentStepComponent = <Step3 initValue={form} backStep1={backStep1} backStep2={backStep2} onNext={goStep4} className={clsx(activeStep !== 2 && classes.hide)}></Step3>;
    } else if (activeStep === 3) {
        currentStepComponent = <Step4 initValue={form} className={clsx(activeStep !== 3 && classes.hide)}></Step4>;
    } else {
    }
    // console.log(mobile);
    return (
        <>
            <Card className={classes.root}>
                <CardContent>
                    {mobile ? (
                        <MobileStepper
                            classes={{
                                progress: classes.mobileStepper,
                            }}
                            variant="progress"
                            steps={steps.length}
                            position="static"
                            activeStep={activeStep}
                            backButton={<></>}
                            nextButton={<></>}
                        />
                    ) : (
                        // <Stepper activeStep={activeStep} classes={{ root: classes.steppeRoot }}>
                        //     {steps.map((label, index) => {
                        //         const stepProps: { completed?: boolean } = {};
                        //         const labelProps: { optional?: React.ReactNode } = {};
                        //         // if (isStepOptional(index)) {
                        //         //     labelProps.optional = <Typography variant="caption">Optional</Typography>;
                        //         // }
                        //         // if (isStepSkipped(index)) {
                        //         //
                        //         // }
                        //         // stepProps.completed = activeStep === 4;
                        //         return (
                        //             <Step key={label} {...stepProps}>
                        //                 <StepLabel {...labelProps}>{label}</StepLabel>
                        //             </Step>
                        //         );
                        //     })}
                        // </Stepper>
                        <></>
                    )}
                    {currentStepComponent}
                </CardContent>
            </Card>
        </>
    );
}
