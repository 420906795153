export default function addGoogleAnalysis(MEASUREMENT_ID) {
    console.log('GA', MEASUREMENT_ID);
    (function (d) {
        const script = d.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.onload = function () {
            // remote script has loaded
        };
        script.src = `https://www.googletagmanager.com/gtag/js?id=${MEASUREMENT_ID}`;
        d.getElementsByTagName('head')[0].appendChild(script);
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());

        gtag('config', `${MEASUREMENT_ID}`);
    }(document));
}