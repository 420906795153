import { createContext, useContext } from "react";

export interface AppContextProps {
    // lang: string;
    // setLang: (value: string) => void;
    publicKey?: string | null;
    // loading: boolean;
    // setLoading: (value: boolean) => void;
}
const AppContext = createContext<AppContextProps | null>({
    // lang: "en",
    // setLang: () => {},
    publicKey: null,
    // loading: false,
    // setLoading: () => {},
});
const UseAppContext = (): AppContextProps | null => {
    return useContext<AppContextProps | null>(AppContext);
};
export default AppContext;
export { UseAppContext, AppContext };
