import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Linkify from 'react-linkify';
import clsx from "clsx";
import Alert from "@material-ui/lab/Alert";
import { Box,Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { ShopDetail, ShopInfo, YoyakuBody, PersonalInfo, YoyakuCourse } from "../../common/interface";
import ToretaApi from "../../ToretaApi";

import Step3 from "./Step3";//reservation
import Step4 from "./Step4";//credit card payment

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3),
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(0.5),
            },
        },
        steppeRoot: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
        },
        media: {
            height: 0,
            paddingTop: "56.25%", // 16:9
        },
        expand: {
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest,
            }),
        },
        required: {
            color: "red",
        },
        expandOpen: {
            transform: "rotate(180deg)",
        },
        avatar: {
            backgroundColor: red[500],
        },
        hide: {
            display: "none",
        },
        mobileStepper: {
            minWidth: "100%",
            flexGrow: 1,
        },
    })
);
const initValue: YoyakuBody = {
    adults: 0,
    children: 0,
    target_date: "2000-01-01",
    start_time: null,
    seats: 0,
    table_category: null,
    last_name: "",
    first_name: "",
    country_code: "",
    phone: "",
    email: "",
    courses: [],
    note: "",
    email_template: "",
    optin_dm: true,
    language:""
};

const judgeMobile = () => window.innerWidth < 768;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
type RouteParams = {
    hash: "" | string;
};
export default function RecipeReviewCard() {
    const classes = useStyles();
    const { hash } = useParams<RouteParams>();
    const { t } = useTranslation();
    const shop: ShopDetail = useSelector((state: any) => state.shop);
    const shopinfo: ShopInfo = useSelector((state: any) => state.shopinfo);
    const toretaApi = ToretaApi();
    // const appContext = UseAppContext();
    const [mobile, setMobile] = React.useState(judgeMobile());

    const [form, setForm] = React.useState<YoyakuBody>( {
        ...initValue,
        courses: [
            {
                id: "",
                count: 0,
            },
        ] as YoyakuCourse[],
    });

    // const [seatInfos, setSeatInfos] = React.useState<ToretaReservationsDaysResponse>({});
    const [activeStep, setActiveStep] = React.useState(2); //JUMP TO STEP3

    const [reservationNo, setReservationNo] = React.useState<number | null>(null);
    // const [skipped, setSkipped] = React.useState(new Set<number>());
    const [payable, setPayable] = React.useState(false);
    React.useEffect(() => {
        if (!shop.public_key) {
            //console.log("!shop.public_key, reserve_queue hash:" , hash);
            toretaApi.GetShopDetail();
            toretaApi.GetShopOther();
        }
        if (shop.public_key) {
            //console.log("shop.public_key: " , shop.public_key , hash);

            //replace Form input with record (YoyakuBody object by staff input)
            toretaApi.GetReserveByStaff(hash).then( ( record: any ) => {
               //console.log(record);
               setReservationNo(record.reserveNo);
               //set payable true when reserve_queue status is allow to pay (0:created & 1:notify )
               if(record.status===0 || record.status===1){
                    setPayable(true);
               }
               setForm( record );
            });
            
        }

        const onResize = () => {
            setMobile(judgeMobile());
        };

        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [shop]);

    const steps = [t("RESERVE_INFO.EXPLAIN"), t("PERSONAL_INFO.EXPLAIN"), t("RESERVE_CONFIRMATION.BUTTON_TEXT")];
    steps.push(t("PAYMENT.CRESIT"));

    const backStep1 = () => {
        //setActiveStep(0);
    };
    const backStep2 = () => {
        //setActiveStep(1);
    };

    const goStep4 = (newdata: PersonalInfo): void => {
        //console.log("1 Pay button clicked , call toretaApi.BeginPayStripe");
        const newform = { ...form, ...newdata };
        toretaApi.BeginPayStripe(//CHECKOUT_STEP:1
            newform, //initValue
            "", //stripe clientSecret (init)
            () => {//nextAction
                    //console.log("goStep4, do nextAction");
                    setForm(newform);
                    setActiveStep(activeStep + 1);
            },
            () => {//failAction
                //console.log("goStep4, do failAction");
            }
        ).then((response) => {
            //console.log("goStep4 BeginPayStripe, response:",response);
        })
        .catch((e)=>{
            //console.log("goStep4 BeginPayStripe, error:",e);
        });;
    };

    let currentStepComponent = null;
    if (activeStep === 2) {
        currentStepComponent = <Step3 initValue={form} payable={ payable } 
            backStep1={backStep1} backStep2={backStep2} onNext={goStep4} 
        className={clsx(activeStep !== 2 && classes.hide)}></Step3>;
    }
    if (activeStep === 3) {
        currentStepComponent = <Step4 initValue={form} className={clsx(activeStep !== 3 && classes.hide)}></Step4>;
    }
    return (
        <>
            <Card className={classes.root}>
                <CardContent>
                    <Box mt={2} mb={3}>
                            <Typography variant="body1" component="h2" gutterBottom>
                            <Box fontWeight="fontWeightBold" m={0} textAlign="center">
                                {t("FINISH.RESERVE_NO")} #{reservationNo}
                            </Box>
                            </Typography>                
                    </Box> 
                    {payable ? (<> 
                    <Box mt={2} mb={3}>
                        <Alert severity="warning">
                            <Box fontWeight="fontWeightBold">{t("RESERVE_CONFIRMATION.EXPLAIN")}</Box>
                        </Alert>
                    </Box>
                    </>) : null}

                    {activeStep !== 3 &&
                        <>
                            <Typography variant="h6" component="h2" gutterBottom>
                                <Box fontWeight="fontWeightBold" m={1}>
                                    {t("HOME.EXPLAIN")}
                                </Box>
                            </Typography>
                            <Box p={1} />
                            <Typography variant="body1" component="p" gutterBottom>
                                {activeStep !== 3 && shop.header_message &&
                                    shop.header_message.split("\n").map(function (item, key) {
                                        return (
                                            <span key={key}>
                                                <Linkify>{item}</Linkify>
                                                <br />
                                            </span>
                                        );
                                    })
                                }
                            </Typography>
                        </>
                    }
                    {currentStepComponent}
                </CardContent>
            </Card>
        </>
    );
}
