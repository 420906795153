import React, { useState } from "react";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Box, Grid, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { CardNumberElement, CardCvcElement, CardExpiryElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import StripeInput, { ELEMENT_OPTIONS } from "../Reserve/Step4/Stripe/StripeInput";
import ToretaApi from "../../ToretaApi";
import { logEvent, ErrorResult } from "../Reserve/Step4/Stripe/utils";
import { GreenButton } from "../../Components";
import Actions from "../../Actions/Actions";
import { ShopDetail, YoyakuBody, ShopInfo } from "../../common/interface";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        alignItemsAndJustifyContent: {
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
        },
        tableTitle: {
            fontWeight: "bolder",
        },
    })
);

type Props = {
    clientSecret: string;
    pm: string;
    fee: number;
    reservationNo: string;
    toretaInfo: YoyakuBody;
};

const CheckoutForm: React.FunctionComponent<Props> = ({ clientSecret, pm, fee, reservationNo, toretaInfo }) => {
    const classes = useStyles();
    const toretaApi = ToretaApi();
    const usenewPm = pm === null || pm === "";
    // const { transformStattTime } = toretaApi;

    const { t } = useTranslation();
    const elements = useElements();
    const stripe = useStripe();
    const [name, setName] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState(null);
    const shop: ShopDetail = useSelector((state: any) => state.shop);
    const shopinfo: ShopInfo = useSelector((state: any) => state.shopinfo);
    React.useEffect(() => {
        if (!shopinfo.courses) {
            toretaApi.GetShopOther();
        }
        // eslint-disable-next-line
    }, [shop, shopinfo, toretaApi.publicKey]);
    const finishWork = async () => {
        // event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        Actions.loadingControl();
        const cardElement = elements.getElement(CardNumberElement);
        let usePaymentMethod: any = pm;
        if (usenewPm) {
            usePaymentMethod = {
                card: cardElement as any,
                billing_details: {
                    name: name,
                    email: toretaInfo.email,
                },
            };
        }
        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: usePaymentMethod,
        });
        // console.log(paymentIntent, error);
        if (paymentIntent && paymentIntent.status === "succeeded") {
            //TODO: check if double reservation
            await toretaApi.FinishStripeFund(paymentIntent.id);
        } else {
            //console.error(error);
            setErrorMessage(t("PAYMENT.ERROR.CC_ERROR"));
        }

        Actions.loadingControl(false);
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("FUND.FEE")}</TableCell>
                            <TableCell align="left">
                                <Typography variant="h6" component="h2" gutterBottom>
                                    <Box fontWeight="fontWeightBold" m={0}>
                                        {`${fee} ${shop.currency}`}
                                    </Box>
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("FINISH.RESERVE_NO")}</TableCell>
                            <TableCell align="left">{`${reservationNo}`}</TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("RESTAURANT_NAME")}</TableCell>
                            <TableCell align="left">{shop.name}</TableCell>
                        </TableRow>
                        {/* <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("PARTY_SIZE")}</TableCell>
                            <TableCell align="left">
                                {toretaInfo.seats}
                                {t("PEOPLE")}
                            </TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("DATE_TIME")}</TableCell>
                            <TableCell align="left">
                                {toretaInfo.target_date} {transformStattTime(toretaInfo.start_time as number)} {t("IN")}
                                {toretaInfo.end_time ? " " + transformStattTime(toretaInfo.end_time as number) + " " + t("OUT") : ""}
                            </TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("TABLE_CAETGORY")}</TableCell>
                            <TableCell align="left">{shopinfo.tables?.find((a) => a.key_name === toretaInfo.table_category)?.display_name}</TableCell>
                        </TableRow>
                        {shop.course_required ? (
                            <>
                                <TableRow hover>
                                    <TableCell className={classes.tableTitle}>{t("RESERVE_INFO.SELECT_COURSE")}</TableCell>
                                    <TableCell align="left">
                                        {" "}
                                        {toretaInfo.courses?.map((course) => {
                                            const menu = shopinfo.courses?.find((a) => a.id === course.id);
                                            if (!menu) {
                                                return <></>;
                                            } else {
                                                return (
                                                    <>
                                                        {menu?.name}
                                                        {"  "}
                                                        {t("RESERVE_INFO.IN_TAX", {
                                                            price: menu?.price,
                                                            seats: course.count,
                                                        })}
                                                        <br />
                                                    </>
                                                );
                                            }
                                        })}
                                    </TableCell>
                                </TableRow>
                            </>
                        ) : (
                            <></>
                        )}
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("REQUEST")}</TableCell>
                            <TableCell align="left">{toretaInfo.note}</TableCell>
                        </TableRow> */}
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("NAME")}</TableCell>
                            <TableCell align="left">
                                {toretaInfo.title ? `${toretaInfo.title}. ` : ""}
                                {`${toretaInfo.first_name} ${toretaInfo.last_name}`}
                            </TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("MOBILE")}</TableCell>
                            <TableCell align="left">{toretaInfo.phoneview ? toretaInfo.phoneview : toretaInfo.phone}</TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell className={classes.tableTitle}>{t("EMAIL")}</TableCell>
                            <TableCell align="left">{toretaInfo.email}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Box my={3}>
                {errorMessage && (
                    <Alert severity="error">
                        <Box fontWeight="fontWeightBold">
                            <ErrorResult>{errorMessage}</ErrorResult>
                        </Box>
                    </Alert>
                )}
                {/* {paymentMethod && <Result>Got PaymentMethod: {paymentMethod.id}</Result>} */}
            </Box>
            {usenewPm ? (
                <Grid container spacing={2} alignContent={"center"}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label={t("NAME")}
                            value={name}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="cardNumber"
                            fullWidth
                            variant="outlined"
                            label={t("PAYMENT.CARD.NUMBER")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputComponent: StripeInput as any,
                                inputProps: {
                                    component: CardNumberElement,
                                    onBlur: logEvent("blur"),
                                    onChange: logEvent("change"),
                                    onFocus: logEvent("focus"),
                                    onReady: logEvent("ready"),
                                    options: ELEMENT_OPTIONS,
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="onBlur"
                            fullWidth
                            variant="outlined"
                            label={t("PAYMENT.CARD.EXPIRY")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputComponent: StripeInput as any,
                                inputProps: {
                                    component: CardExpiryElement,
                                    onBlur: logEvent("blur"),
                                    onChange: logEvent("change"),
                                    onFocus: logEvent("focus"),
                                    onReady: logEvent("ready"),
                                    options: ELEMENT_OPTIONS,
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="cvc"
                            fullWidth
                            variant="outlined"
                            label={t("PAYMENT.CARD.EXPIRY")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputComponent: StripeInput as any,
                                inputProps: {
                                    component: CardCvcElement,
                                    onBlur: logEvent("blur"),
                                    onChange: logEvent("change"),
                                    onFocus: logEvent("focus"),
                                    onReady: logEvent("ready"),
                                    options: ELEMENT_OPTIONS,
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            ) : (
                <></>
            )}
            <Box textAlign="center" mt={5} mb={2}>
                <GreenButton disabled={!stripe} variant="contained" color="primary" onClick={finishWork}>
                    {t("FUND.CONFIRM")}
                </GreenButton>
            </Box>
        </>
    );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
type RouteParams = {
    piId: undefined | string;
};
const App = () => {
    const toretaApi = ToretaApi();
    const history = useHistory();
    const { piId } = useParams<RouteParams>();
    const shop: ShopDetail = useSelector((state: any) => state.shop);

    React.useEffect(() => {
        if (!shop.public_key) {
            toretaApi.GetShopDetail();
        }
    }, [shop, toretaApi]);
    const [stripePromise, setStripePromise] = useState<any | null>(null);
    const [data, setData] = useState({ clientSecret: "", pm: "", fee: 0, reservationNo: "", toretaInfo: {} as YoyakuBody });
    const refreshStripe = () => {
        toretaApi.GetStripeFund(piId).then((data: any) => {
            if (data) {
                const newStripe = loadStripe(data.PK);
                setStripePromise(newStripe);
                setData({
                    pm: data.pm,
                    fee: data.fee,
                    clientSecret: data.clientSecret,
                    reservationNo: data.reservationNo,
                    toretaInfo: data.toretaInfo,
                });
            } else {
                history.push("/error/CONTACT_SYSTEM_MANAGER");
            }
        });
    };
    React.useEffect(() => {
        refreshStripe();
        // eslint-disable-next-line
    }, [toretaApi.publicKey]);
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm clientSecret={data.clientSecret} pm={data.pm} fee={data.fee} reservationNo={data.reservationNo} toretaInfo={data.toretaInfo} />
        </Elements>
    );
};

export default App;
