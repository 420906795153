import DatePicker from "./Datepicker";
import { Theme, withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
// import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";
const GreenButton = withStyles((theme: Theme) => ({
    root: {
        minWidth: "300px",
        [theme.breakpoints.down("sm")]: {
            minWidth: "200px",
        },
       
        color: "white",
        paddingTop: "15px",
        paddingBottom: "15px",
        fontSize: "16px",
        backgroundColor: green[500],
        "&:active": {
            color: "white",
            backgroundColor: green[700],
        },
        "&:hover": {
            color: "white",
            backgroundColor: green[700],
        },
    },
}))(Button);

export { GreenButton, DatePicker };
