import React from "react";
 

export default function SGPrivacy() {
    return (
        <>
            <div className="etcContent">
                These Terms of Service (“these Terms”) prescribe the various rights and obligations arising between
                Toreta Asia Pte Ltd (“Toreta”) and member stores and the rules that need to be observed upon Toreta
                providing its service “Toreta” (“Service”) to member stores. When using the Service, member stores are
                required to agree to these Terms. Please make sure to read these Terms in their entirety and confirm the
                subject matter of these Terms.
                <h3>Account</h3>A dedicated account is required for using the Service. The account will be created by
                the member store and approved and issued by Toreta. Please do not inform the account or password of the
                Service to a third party. The member store is independently responsible for the acts that are conducted
                on or through Toreta’s account. Please refrain from reusing the password of the account in the service
                or application of a third party. Toreta will not be liable for any loss or damage arising as a result of
                the member store failing to secure the safety of the account. Furthermore, if the member store discovers
                any unauthorized use of the account, the member store is recommended to immediately change the password.
                <h3>Use of Service</h3>
                Before using the Service, Toreta and the member store will execute an application form (“Application
                Form”) which prescribes the effective term, fee and other necessary matters. As a result of executing
                the Application Form, an individual license agreement (“License Agreement”) according to the various
                provisions of these Terms will be concluded between Toreta and the member store. iPad or other terminal
                devices, communication line and other communication environment required for receiving the Service shall
                be prepared and maintained by the member store under its own cost burden and responsibility; save for
                cases where a separate agreement has been reached between Toreta and the member store. Please do not use
                the Service illegally. The following acts are prohibited: (1) obstruction of the Service or access to
                the Service according to a method other than based on the application provided by Toreta and procedures
                prescribed by Toreta; (2) a party engaged in a business in competition with Toreta creating and using an
                account; (3) allowing a party engaged in a business in competition with Toreta or any other third party
                to use the account or password of the Service; (4) act that is in violation of laws; and (5) act that
                infringes upon the intellectual property rights of a third party. In addition to the above, if the
                member store fails to observe the terms and policies of Toreta, if Toreta will conduct an investigation
                regarding acts that are suspected of being illegal, or if Toreta determines that an act conducted by the
                member store is illegal or corresponds to a prohibited act, Toreta may temporarily suspend or
                discontinue the provision of the Service to the member store or the user.
                <h3>Service Fee</h3>
                As consideration for using the Service, the member store shall pay to Toreta the monthly usage fee
                indicated in the Application Form and the SMS transmission/FAX transmission cost. The member store shall
                pay the monthly usage fee and the SMS transmission/FAX transmission cost by the payment date indicated
                in the Application Form according to the method designated by Toreta. Bank charges and other fees
                required for the payment shall be borne by the member store. If the member store neglects to make
                payment, the member store must pay to Toreta a delinquency charge at a rate of 10% per annum (prorated
                with 1 year being 365 days). The effective term of the License Agreement shall be a period of one (1)
                year from the date of application indicated in the Application Form; provided, however, that, if the
                member store does not declare its intent of not renewing the License Agreement at least one (1) month
                prior to the expiration of the effective term of the License Agreement, the License Agreement will be
                automatically renewed for a period of one (1) year under the same terms and conditions simultaneously
                with the expiration of the effective term, and the same shall apply to any subsequent terms. Even during
                the effective term, the member store may cancel the License Agreement by providing a one (1) month prior
                notice according to the method to be separately prescribed by Toreta.
                <h3>Change of Service</h3>
                Toreta is entitled to enforce new measures and policies in relation to all or a part of the Service.
                Furthermore, the member store understands that access to or use of the Service may be hindered for a
                certain period or based on a certain method due to the various measures and policies enforced by Toreta,
                and acknowledges that Toreta will not bear any responsibility or obligation associated with such
                operation or results. Moreover, the member store acknowledges that Toreta will not bear any obligation
                against the memory store or a third party regarding the change, suspension or abolishment of all or an
                arbitrary part of the Service.
                <h3>Transmission of Information for Attracting Customers of Member Store</h3>
                Toreta may transmit information of the member store based on Toreta’s own medium for attracting
                customers of the member store.
                <h3>Analysis, etc.</h3>
                The member store agrees to Toreta acquiring the information related to the usage of the application by
                the member store and performing statistical analysis using research tools of a third party for improving
                the Service.
                <h3>Sending of Crash Report</h3>
                If the Application suffers a defect, a crash report describing the details of the defect may be
                automatically sent from Toreta to the member store. The member store agrees in advance regarding the
                fact that a crash report of the defect may be sent from Toreta to the member store.
                <h3>Customer Information</h3>
                Toreta may keep in custody the customer’s reservation details and other personal information of the
                customer who made the reservation in the course of the member store using the Service. Toreta may use
                the personal information and other data of the customer in its custody for providing the Service or
                improving or adding functions, or for otherwise executing business operations required by Toreta. Upon
                acquiring personal information from customers, the member store shall notify the purpose of use thereof
                and take other appropriate measures in accordance with the Personal Information Protection Act and other
                applicable laws and ordinances, and the member store shall resolve any and all disputes arising with the
                customer in connection therewith under the member store’s responsibility and cost burden. Toreta will
                observe the Act on the Protection of Personal Information in handling the personal information, and take
                security measures in accordance with the personal information protection rules to be formulated
                internally.
                <h3>Exclusion of Antisocial Forces</h3>
                Toreta warrants that Toreta does not correspond to antisocial forces, and is not cooperating with or
                involved in the maintenance, operation or management of antisocial forces through the provision of funds
                or other means, and the member store is also required to warrant the same.
                <h3>Suspension of Service</h3>
                Upon corresponding to any one of the following items, Toreta may permanently discontinue or temporarily
                suspend all or a part of the use of the Service by notifying the member store in advance (but without a
                prior notice in cases of emergency): (1) when conducting inspection or maintenance work of the computer
                system in relation to the Service; (2) when the computer system or communication line is suspended due
                to an accident; (3) when the Service can no longer be operated due to fires, blackouts, calamities or
                other force majeure; or (4) when Toreta otherwise determines that the discontinuation or suspension of
                the Service is required. Toreta will not be liable for any damage suffered by the member store due to
                the measures of discontinuation or suspension of the Service based on (1) to (4) above; save for cases
                where such damage was caused by the intention or gross negligence of Toreta.
                <h3>Disclaimer of Warranties</h3>
                Excluding cases where Toreta is to suspend or discontinue the Service (refer to the section of
                “Suspension of Service”), Toreta will exert efforts so that the user can continuously use the Service,
                but there is a possibility that the Service may become unavailable for a given period of time due to
                network congestion or other reasons. While Toreta will exert efforts so that the Service will operate
                reliably without any suspension or discontinuation, Toreta does not guarantee that it will correct all
                errors in the provided applications and services. While Toreta will exert efforts for safely storing the
                customer’s reservation details and other data sent by the member store during the use of the Service,
                there is a possibility that the data may be lost due to the malfunction of the external server used by
                the Service or other reasons. Please back up such data and take other preservative measures under the
                member store’s responsibility and cost burden. Furthermore, Toreta does not guarantee that such data
                will not be lost, damage or erased, and Toreta will not be liable for such loss, damage or erasure
                unless such loss, damage or erasure was caused by the intention or gross negligence of Toreta. The
                Service will be provided as is (in its present condition). Accordingly, even in cases where the member
                store obtains information related to the Service directly or indirectly from Toreta, Toreta will not
                offer any kind of guarantee to the member store beyond the subject matter prescribed in these Terms.
                Whether the use of the Service is in violation of laws or in breach of internal rules of industry groups
                that are applicable to the member store shall be researched by the member store under its own
                responsibility and cost burden. Furthermore, Toreta does not guarantee that the member store’s use of
                the Service is compliant with laws and internal rules of industry groups that are applicable to the
                member store.
                <h3>Limitation of Liability</h3>
                Toreta shall not be liable for compensating any damage suffered by the member store in relation to the
                Service, unless such damage was caused by the intention or gross negligence of Toreta. Even in cases
                where Toreta bears liability for damages against the member store irrespective of the provisions of this
                paragraph and other provisions exempting Toreta’s liability for damages based on the application of the
                Consumer Contract Act or other reasons, Toreta’s liability for damages shall be limited to direct and
                ordinary damage, and shall not include indirect damage such as lost profits and loss of business
                opportunities. Furthermore, Toreta’s liability for damages shall be capped at the total amount of fees
                actually received from the member store in the past three (3) months based on the License Agreement in
                relation to such damage.
                <h3>Termination of License Agreement</h3>
                If the member store corresponds to any of the events listed in each of the following items, Toreta may
                immediately terminate the License Agreement by providing a written notice to the member store: (1) the
                member store breaches these Terms, and fails to correct such breach even after the lapse of fifteen (15)
                days after receiving a notice requesting the correction of such breach; (2) the member store suspends
                its payment or falls into a state of insolvency, or a petition is filed against the member store for
                commencement of bankruptcy proceedings, commencement of civil rehabilitation proceedings, commencement
                of corporate reorganization proceedings, or other similar proceedings; (3) a bill or a check
                underwritten by the member store is dishonored, or the member store receives a disposition for
                suspension of transaction of a clearinghouse or any other similar measures; (4) the member store is
                subject to provisional attachment or provisional disposition or receives a similar order and the effect
                thereof continues for fifteen (15) days or longer, or a petition is filed against the member store for
                attachment or auction; (5) the member store receives a disposition for failure to pay taxes and public
                dues; (6) the member store is dissolved (excluding dissolution based on merger), commences liquidation
                or transfers all of its business to a third party; (7) the member store receives a disposition from
                competent authorities for suspension of business, revocation of business license, cancellation of
                business registration or any other similar disposition; (8) the member store’s asset or credit status
                becomes aggravated, or there is due cause to acknowledge the possibility of the same; (9) the member
                store’s director, auditor, employee or any other member or shareholder, customer, counsel or any other
                advisor corresponds to antisocial forces, or it is discovered that there is some kind of relation or
                involvement with antisocial forces and such relation or involvement is not terminated within a
                reasonable period after receiving a notice requesting the termination thereof; (10) the member store
                permits a third party to illegally use the Service for conducting business that is in competition with
                Toreta in breach of these Terms; or (11) Toreta otherwise determines that the member store is
                inappropriate. Furthermore, if the license (“Original License”) that was granted to Toreta by a third
                party lapses for any reason, Toreta may immediately terminate the License Agreement by notifying the
                member store to such effect. The exercise of the right of termination based on the foregoing reasons
                does not preclude Toreta from seeking damages against the member store or exercising other rights
                against the member store.
                <h3>Intellectual Property Rights in Application</h3>
                All intellectual rights in the application (“Application”) provided by Toreta shall belong to Toreta or
                the party that is granting a license thereof to Toreta. The member store understands that Toreta’s
                authorization to exploit the Application to the member store based on these Terms are subject to the
                Original License, and, therefore, if the Original License lapses for any reason, there is a possibility
                that the Service may become unavailable. Furthermore, Toreta shall not be liable for compensating any
                damage suffered by the member store due to the lapse of the Original License. Toreta’s provision of the
                Application to the member store based on these Terms does not imply the transfer of intellectual
                property rights or licensing of the Application, unless expressly acknowledged in these Terms.
                Similarly, the member store is not granted any rights for using the brands and logos used in the
                Application. The member store must not replicate or modify the Application or engage in any other act
                that would infringe upon the intellectual property rights of Toreta or the party that is granting a
                license thereof to Toreta.
                <h3>Dispute Resolution related to Application</h3>
                If the member store receives any complaint, claim for damage or any other claim from a third party
                regarding defects or rights in the Application, the member store shall immediately notify Toreta and
                follow the instructions given by Toreta. If the member store receives any complaint, claim for damage or
                any other claim from a third party in relation to the use of the Application, the member store shall
                resolve such complaint or claim entirely under its own responsibility and cost burden. The member store
                shall compensate or indemnify any damage (including attorney’s fees and the amount of damage claimed by
                the third party) or loss suffered or incurred by Toreta in relation to the foregoing complaint or claim.
                <h3>Force Majeure</h3>
                If either party delays the performance of its obligations under these Terms (excluding monetary
                obligations that have matured) due to circumstances beyond its reasonable control (including fires,
                blackouts, hacking, invasion of computer virus, earthquakes, floods, wars, epidemics, embargos, strikes,
                riots, impossibility of procurement of materials and transport facilities, and intervention of
                government authorities), such party shall not be liable for its non-performance against the other party
                during the period that such circumstance is ongoing.
                <h3>Confidentiality</h3>
                The term “confidential information” as used in these Terms shall mean all information related to
                Toreta’s technologies, sales, business operations, finance, organization and other matters which was
                learned by the member store in relation to these Terms as a result of such information being provided or
                disclosed by Toreta verbally, in writing or with a recording medium or the like; provided, however, that
                the following information shall be excluded from confidential information: (1) information which had
                been public knowledge or in the possession of the member store at the time that such information was
                provided or disclosed from Toreta or learned by the member store; (2) information which became public
                knowledge through publications or the like after such information was provided or disclosed from Toreta
                or learned by the member store; (3) information which was legitimately acquired from a third party,
                which is authorized to provide or disclose such information, without having to bear any confidentiality
                obligation; (4) information which was independently developed without having to depend on the
                confidential information; and (5) information for which Toreta acknowledged in writing that the
                maintenance of confidentiality thereof is not required. The member store shall use the confidential
                information only for the purpose of these Terms, and must not provide, disclose or divulge Toreta’s
                confidential information to a third party without obtaining the written approval of Toreta; provided,
                however, that the member store may disclose Toreta’s confidential information based on laws or an order,
                demand or request from the court or administrative agency. When the member store receives the foregoing
                order, demand or request, the member store must promptly notify Toreta to such effect. Furthermore, when
                these Terms are terminated or when requested by Toreta, the member store shall at any time promptly
                return or destroy the confidential information, documents or recording mediums describing or containing
                confidential information, and all replications thereof in accordance with the instructions given by
                Toreta.
                <h3>Notice</h3>
                All notices based on these Terms shall be made by personal delivery, registered mail or email to the
                other party’s address or email address indicated in the Application Form. Either party may change the
                contact information indicated on the Application Form by notifying the other party based on this
                paragraph. If these notices do not reach the other party due to reasons that are attributable to the
                other party, such as the whereabouts of the other party being unknown, such notices shall be deemed to
                have arrived on the day that two (2) weeks have elapsed from the date of dispatch thereof.
                <h3>Amendment of these Terms</h3>
                Toreta may freely amend the subject matter of these Terms. When Toreta amends these Terms, Toreta will
                promptly notify the amendments to the member store. If the member store uses the Service after being
                notified of the amendments or fails to raise an objection within the period designated by Toreta, it
                shall be deemed that the member store has agreed to the amendments of these Terms.
                <h3>No Assignment, etc.</h3>
                Without obtaining Toreta’s prior written approval, the member store may not assign or offer as security
                to a third party or otherwise dispose its contractual status under the License Agreement or the rights
                and obligations based on the License Agreement. Toreta may assign to its subsidiary or affiliate, or to
                the assignee company upon assigning the Service and incidental business to another company, its status
                under the License Agreement or the rights or obligations under these Terms as well as the member store’s
                Registration Information and other customer information pursuant to the transfer of business, and the
                member store shall agree in advance to the foregoing assignment. The transfer of business set out in
                this paragraph shall include, in addition to ordinary transfer of business, all cases where the business
                is transferred through company split or other means.
                <h3>Entire Agreement</h3>
                These Terms constitute the entire agreement between the parties of these Terms regarding the matters
                included in these Terms, and supersede all prior agreements, representations and understandings, whether
                verbal or in writing, related to the matters set out in these Terms between the parties.
                <h3>Separability</h3>
                Even if any one of the provisions of these Terms or a part thereof is determined to be invalid or
                unenforceable, the remainder of the provisions of these Terms or the remainder of the section of the
                provision in which a part thereof was determined to be invalid or unenforceable shall continue to remain
                in full effect. The parties of these Terms shall exert efforts to revise, to the extent required, the
                invalid or unenforceable provision or section thereof so that it will be legal and enforceable, and
                attain the same level of legal and economic effect as the gist of such invalid or unenforceable
                provision or section thereof.
                <h3>Governing Law and Agreed Jurisdiction</h3>
                These Terms shall be governed by the laws of Singapore, and any dispute arising in relation to these
                Terms shall be submitted to a Singaporean court as the competent court of agreed jurisdiction for the
                first instance.
                <h3>Consultation</h3>
                With regard to matters that are not prescribed in these Terms or when doubts arise in the interpretation
                of these Terms, the parties shall consult in good faith and resolve such matters or doubts pursuant to
                the provisions of laws and business practices.
                <h3>Special Provision on Web Reservation System</h3>
                In addition to the foregoing provisions, the following provisions shall be applied to the web
                reservation system. While the web reservation system is provided by Toreta to customers, when a customer
                uses this system and makes a reservation with a member store and such reservation is finalized, a
                contract based on the reserved contents will be concluded between the customer and the member store. All
                problems arising in relation to the conclusion, cancelation or amendment of this contract shall be
                directly resolved between the member store and the customer, and Toreta will not be involved in any way.
                When a customer is to cancel or change a reservation after making an official reservation with the
                member store through the web reservation system, the procedures for cancelling or changing the
                reservation will be taken through this system or by directly contacting the member store, but in the
                foregoing case the conditions, deadline, fees and other matters shall be prescribed by the member store
                under its own responsibility, and Toreta will not be involved in any way. If there is any change to the
                information registered in the web reservation system, the member store shall change such information
                under its own responsibility, and Toreta shall not be liable for any damage arising as a result of any
                delay in making such change. Since the web reservation system will be provided by Toreta to customers,
                the customer’s personal information provided by the customer through this system shall belong to Toreta.
                Toreta will appropriately handle the personal information of customers in accordance with the terms of
                use between Toreta and customers, Toreta’s privacy policy, the Personal Information Protection Act and
                other applicable laws and ordinances. When the customer subsequently makes a reservation through this
                system, the personal information of that customer will be provided to the member store based on the
                consent of the customer. The member store shall appropriately handle the provided personal information
                of the customer under its own responsibility in accordance with the Personal Information Protection Act
                and other applicable laws and ordinances.
                <h3>Special Provision on POS Connect</h3>
                For the use of POS Connect, in addition to these Terms, the following provisions shall apply. Toreta
                shall not be subject to any restrictions from the member store or third parties as to the selection of
                which POS system to be used. While Toreta POS Connect coordinate data between POS system and the
                Service, Toreta shall not be responsible for any damage caused to any service establishment due to
                measures including suspension, interruption or others of a POS system through which data is coordinated.
                In the event of any malfunction in coordinating data between the Service and POS system, Toreta will
                endeavour to eliminate such malfunctioning and prevent recurrence thereof.
            </div>
        </>
    );
}
