import React from "react";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
// import { useSelector } from "react-redux";
// import CreditCardInput from "react-credit-card-input";
import "react-credit-cards/es/styles-compiled.css";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import ToretaApi from "../../ToretaApi";
// import Swal from "sweetalert2";
import { YoyakuBody, ShopDetail } from "../../common/interface";
const useStyles = makeStyles(() =>
    createStyles({
        alignItemsAndJustifyContent: {
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
        },
        alignItemsAndLeftContent: {
            alignItems: "left",
            justifyContent: "left",
            textAlign: "left",
        },
    })
);

type Props = {
    initValue: YoyakuBody;
    // onNext: (data: ReservationResponse) => void;
    isDisplayNotes: Boolean;
};

const DepositInfo: React.FunctionComponent<Props> = ({ initValue, isDisplayNotes }) => {
    // const currentLanguage: string = useSelector((state: any) => state.lang);
    const classes = useStyles();
    const shop: ShopDetail = useSelector((state: any) => state.shop);
    const [fee, setFee] = React.useState<number | null>(null);
    const toretaApi = ToretaApi();
    const { t } = useTranslation();
    interface Map {
        [key: string]: any;
        [index: number]: any;
    }
    const dicCurrency:Map={
        "SGD":"S$",
        "TWD":"NT$",
        "MYR":"RM",
        "THB":"฿",
        "JPY":"¥",
    }
    const getCurrency = (key: string) =>{
        var c= Object.keys(dicCurrency).filter((val,ind) => {return val==key;});
        if(c.length>0){
            return dicCurrency[key];
        }
        return key;
    }
    const formatter = new Intl.NumberFormat('ja-JP', {
        //style: 'currency',
        currency: 'JPY',
      });
    const formatterEn = new Intl.NumberFormat('en-US', {
        currency: 'USD',
    });
    const getCurrencyAmt = (amt: number) =>{
        
        if(shop.currency=="JPY"){
            return formatter.format(Math.trunc(amt));
        }
        return formatterEn.format(amt);
    }
    React.useEffect(() => {
        toretaApi.GetDebitAmount(initValue).then((fee) => {
            shop.auth_hold_amount=fee;
            setFee(fee);
        });
        // eslint-disable-next-line
    }, [initValue]);
    return (
        <Grid item xs={12}>
            <br />
            <Typography variant="body1" component="h2" gutterBottom>
            {shop.company_country!="jp"?(
                <Box fontWeight="fontWeightBold" m={0} className={classes.alignItemsAndJustifyContent}>
                    {t("PAYMENT.DEPOSIT")}: {`${fee !== null ? fee : ""} ${shop.currency}`}
                </Box>):(<Box fontWeight="fontWeightBold" m={0} className={classes.alignItemsAndJustifyContent}>
                    {t("PAYMENT.DEPOSIT_JP")
                    .split("\n")
                    .map(function (item, key) {
                        return (
                            <span key={key}>
                                {key>0?(<br />):("")}
                                {item}
                                
                            </span>
                        );
                    })
                    }: {getCurrency(shop.currency)} {`${fee !== null ? getCurrencyAmt(fee) : ""}`}
                </Box>)}
            {shop.company_country!="jp" || !isDisplayNotes?(<></>):(<Box fontWeight="fontWeightBold" m={0} className={classes.alignItemsAndLeftContent}>
                    {t("PAYMENT.NOTE_JP")
                    .split("\n")
                    .map(function (item, key) {
                        return (
                            <span key={key}>
                                {key>0?(<br />):("")}
                                {item}
                                
                            </span>
                        );
                    })
                    }
                </Box>)}
            </Typography>
        </Grid>
    );
};
export default DepositInfo;
