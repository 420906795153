import React from "react";
// import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";
// import CreditCardInput from "react-credit-card-input";
import { Box } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

import "react-credit-cards/es/styles-compiled.css";

// import Swal from "sweetalert2";
import { ShopDetail, YoyakuBody } from "../../../common/interface";
import Depositinfo from "../DepositInfo";
import StipeComponent from "./Stripe";
import ToretaApi from "../../../ToretaApi";
import { useSelector } from "react-redux";
type Props = {
    initValue: YoyakuBody;
    className: any;
    // onNext: (data: ReservationResponse) => void;
};

const Step4: React.FunctionComponent<Props> = (props) => {
    const shop: ShopDetail = useSelector((state: any) => state.shop);
    const toretaApi = ToretaApi();
    React.useEffect(() => {
        toretaApi.ScrollTop();
        // eslint-disable-next-line
    }, [shop]);
    let usedComponent = <StipeComponent initValue={props.initValue}></StipeComponent>;
    return (
        <>
            <Box mb={2} color={red[500]}>
                <Depositinfo initValue={props.initValue} />
            </Box>
            {usedComponent}
        </>
    );
};
export default Step4;
