import React from "react";
import { Box, Card, CardContent, CardMedia, Checkbox, CheckboxProps, Typography, FormControlLabel } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Linkify from 'react-linkify';
import { makeStyles, Theme, createStyles, withStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import { /*NavLink,*/ useHistory } from "react-router-dom";
import ToretaApi from "../ToretaApi";
import { ShopDetail } from "../common/interface";
import { GreenButton } from "../Components";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        media: {
            minHeight: "320px",
            backgroundSize: "contain",
            // paddingTop: "56.25%", // 16:9
        },
        expand: {
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: "rotate(180deg)",
        },
        avatar: {
            backgroundColor: red[500],
        },
    })
);

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        "&$checked": {
            color: green[600],
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
export default function ShopPage() {
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();
    const shop: ShopDetail = useSelector((state: any) => state.shop);
    const [state, setState] = React.useState({
        checked: false,
    });
    const { GetShopDetail, ScrollTop } = ToretaApi();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    React.useEffect(() => {
        GetShopDetail();
        ScrollTop();
        // eslint-disable-next-line
    }, []);

    return (
        <Card className={classes.root}>
            {/* <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                        R
                    </Avatar>
                }
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                }
                title="Shrimp and Chorizo Paella"
                subheader="September 14, 2016"
            /> */}
            {shop.picture && <CardMedia className={classes.media} image={shop.picture} title={shop.name} />}
            <CardContent>
                <Typography variant="h6" component="h2" gutterBottom>
                    <Box fontWeight="fontWeightBold" m={1}>
                        | {t("HOME.EXPLAIN")}
                    </Box>
                </Typography>
                <Box p={1} />
                <Typography variant="body1" component="p" gutterBottom>
                    {shop.header_message &&
                        shop.header_message.split("\n").map(function (item, key) {
                            return (
                                <span key={key}>
                                    <Linkify>{item}</Linkify>
                                    <br />
                                </span>
                            );
                        })}
                </Typography>
                <Box mt={6} textAlign="center">
                    <FormControlLabel control={<GreenCheckbox checked={state.checked} onChange={handleChange} name="checked" />} label={t("HOME.CHECKBOX_LABEL")} />
                </Box>
                <Box mt={1} textAlign="center">
                    <GreenButton
                        variant="contained"
                        color="primary"
                        disabled={!state.checked}
                        onClick={() => {
                            history.push("/reserve-info");
                        }}
                    >
                        {t("HOME.BUTTON_TEXT")}
                    </GreenButton>
                </Box>
            </CardContent>
            {/* <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                    <ShareIcon />
                </IconButton>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions> */}
        </Card>
    );
}
