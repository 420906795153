import React from "react";
import { Box } from "@material-ui/core";

export default function SGPrivacy() {
    return (
        <>
            <div className="area-title">
                <h3>Policies for the protection of personal information</h3>
            </div>
            <p style={{ textAlign: "right" }}>Toreta Asia Pte. Ltd. Hanae Ikehata, Representative Director</p>
            <Box>
                Toreta Asia Pte. Ltd. (hereinafter called the “Company”) recognizes its social mission sufficiently and
                abides by laws and regulations related to the protection of the rights of any principal and personal
                information in conjunction with all personal information handled by the Company. To realize the policies
                set forth below, the Company hereby declares that it will establish a personal information protection
                management system and move forward with company-wide initiatives for continuous improvements of the
                system while always understanding the trends of recent IT technologies, changes in social requirements
                and fluctuations in management environments.
            </Box>
            <ul>
                <li>
                    1. The Company will obtain, use and/or provide personal information only to the extent necessary for
                    executing business legitimately, hiring employees and fulfilling personnel management in the
                    development, sale and operation of reservation systems for restaurants. It will not handle personal
                    information exceeding the scope necessary to achieve the specified purposes of use (unintended use).
                    The Company also will take measures to prevent unintended use.
                </li>
                <li>
                    2. The Company will abide by laws and regulations related to the protection of personal information
                    in every area of jurisdiction associated with the Company as well as guidelines set forth by the
                    Government and other standards.
                </li>
                <li>
                    3. The Company will allocate management resources to align with business realities, and will
                    continuously improve security systems for personal information so that it can prevent risks such as
                    the leakage, loss, and/or damage of personal information through reasonable safety countermeasures.
                    In the unlikely event that any of such risks occurs, it will take corrective measures promptly.
                </li>
                <li>
                    4. The Company will respond to complaints and/or consultations on the handling of personal
                    information promptly, sincerely and appropriately.
                </li>
                <li>
                    5. The Company, considering changes in the environments surrounding the Company, will review the
                    personal information protection management system in a timely and appropriate manner, and
                    continuously facilitate the improvement of the system.
                </li>
            </ul>
            <Box>
                The Company, while distributing this policy to its employees to raise awareness, will also take steps
                such as publication on its website or in brochures so that it can be accessed by anyone at any time.
            </Box>
            <h3>Inquiries</h3>
            <Box>
                Inquiries on the privacy policy should be sent to the following: Contact point for personal information
                at Toreta Asia Pte. Ltd.
                <br></br>
                Telephone: +65 6670 6666
                <br></br>
                E-mail: enquiry@toreta.in
            </Box>
            <h3>Handling of personal information</h3>
            <Box>1. Purposes of use of personal information handled by the Company</Box>
            <ul>
                <li>
                    1) The Company will provide clear written notification to the principal before acquiring personal
                    information for the purpose of use to be obtained through documents (including information acquired
                    through websites or emails).
                </li>
                <li>
                    2) Purposes of use of personal information to be obtained through measures other than those
                    indicated in the preceding paragraph.
                </li>
            </ul>
            <Box>
                – Information of persons who made inquiries (by telephone, email, etc.) To respond to inquiries To
                provide information on the Company’s services – Personal information shared by companies that use
                Toreta’s services To utilize in support operations relating to the use of Toreta’s services such as
                confirmation of order details – Information of engineers of partner companies To manage development
                operations
            </Box>
            <h3>Raising of awareness of personal information applicable for disclosure</h3>
            <Box>
                The Company will respond in the following manner if it receives a request for notification of the
                purposes of use, or a request for the disclosure, correction, addition or removal of content,
                termination of use, deletion, or termination of provisioning to a third party (hereinafter called the
                “request for disclosure, etc.”) from the principal or its agent, in conjunction with personal
                information held by the Company that is applicable for disclosure.
            </Box>
            <h4>a) Name of operating company</h4>
            <Box>Toreta Asia Pte. Ltd.</Box>
            <h4>b) Administrator of personal information</h4>
            <Box>
                Name of administrator: Hanae Ikehata Department: Director, Toreta Asia Pte. Ltd. Contact: Tel. +65 6670
                6666
            </Box>
            <h4 className="m_bottom10">c) Purposes of use of all personal information applicable for disclosure</h4>
            <Box>
                – Personal information of users of the online reservation system To make reservations and deal with
                other related purposes. – Personal information of persons who have made inquiries To respond to
                inquiries and deal with other related purposes. To provide information on the Company’s services. –
                Personal information of persons in charge at client companies To confirm order details and deal with
                related purposes. To provide information on the Company’s services and deal with other related purposes.
                – Information of the Company’s employees To manage personnel and labor matters, operations, health, and
                security for employees and deal with other related purposes. – Information of applicants for employment
                with the Company To communicate with applicants for employment, manage the Company’s recruitment
                operations, and deal with other related purposes.
            </Box>
            <h4 className="m20">
                d) Contact for complaints on the handling of personal information applicable for disclosure Contact
                point for personal information at Toreta Asia Pte. Ltd.
            </h4>
            <Box>Telephone: +65 6670 6666　 E-mail: enquiry@toreta.in</Box>
            <h4>
                e) Procedures for responses to requests for the disclosure, etc. of personal information applicable for
                disclosure.
            </h4>
            <ul>
                <li>
                    1) Contact point for requests for disclosure, etc. Requests for disclosure, etc. should be made to
                    the above contact point for personal information.
                </li>
                <li>
                    2) Procedures pertaining to requests for disclosure, etc. (i) Upon the receipt of a request, the
                    Company will mail a request form specifically prepared for use for each request. ・Notification of
                    purposes of use: “Request for notification of purposes of use of personal information applicable for
                    disclosure”・Disclosure: “Request for disclosure of personal information applicable for
                    disclosure”・Correction, addition or removal, termination of use, deletion, and/or termination of
                    provisioning to a third party: “Request for correction, etc. and/or termination of use, etc. of
                    personal information applicable for disclosure” (ii) The request form that has been filled out, or
                    if the request is made by an agent, a document that proves such fact, as well as a postal money
                    order for the necessary charges (applicable only to a request for notification of purposes of use or
                    disclosure) should be mailed to the above contact point for personal information. (iii) Upon receipt
                    of the above request, the Company will ask you, for identity verification purposes, for information
                    that verifies the identity of the principal, among the information registered with the Company. (iv)
                    Responses by the Company will be made to the principal in writing (mailed in a sealed letter), in
                    principle.
                </li>
                <li>
                    3) Presentation of documents that confirm that the request is made by an agent, if it is made by the
                    agent
                </li>
            </ul>
            <Box>
                If the request for disclosure, etc. is made by an agent, documents that prove that such request is made
                by the agent and documents that verify the identity of the agent should be enclosed.
            </Box>
        </>
    );
}
