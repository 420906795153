import DEF from "../def";
import { localLang } from "../../Const/LangList";
const initialState = localLang;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case DEF.LANG_CHANGE:
            // 補上Localstorage變更語言的部分
            localStorage.setItem("lang", action.value)
            return action.value;
        default:
            return state;
    }
}