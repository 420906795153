import React from "react";
export default function SGPrivacy() {
    return (
        <>
            <div className="etcContent">
                <div className="area-title">
                    <h3>個人資料保護方針</h3>
                </div>

                <p style={{ textAlign: "right" }}>Toreta Taiwan, Inc.　CEO　Hanae Ikehata</p>

                <p>
                    Toreta Taiwan,
                    Inc.（以下簡稱「本公司」）在此聲明，針對本公司蒐集、處理及利用的所有個人資料之保護事宜，本公司在充分認知此為企業之社會責任的前提之下，恪遵相關法規以保護個人之權利及其資料。為了具體展現此方針，本公司特架構如下所示之個人資料保護管理系統，並隨時掌握最新的
                    IT 技術動向、社會需求之變化、經營環境之變動等持續改善之，盡力落實個人資料保護之工作。
                </p>

                <p>
                    此外，本公司已在日本取得由「一般財團法人日本情報經濟社會推進協會」所頒發之「Privacy mark
                    (プライバシーマーク)」認證(如下圖示)，透過此項認證，可證明本公司係為已導入符合日本工業規格「JIS Q
                    15001個人資訊保護管理系統要求事項」相關個人資料管理措施之企業經營者。
                </p>

                <h4 style={{ marginTop: "40px" }}>一、個人資料蒐集、處理及利用之目的</h4>

                <p>您同意本公司蒐集、處理及利用個人資料之目的如下：</p>
                <ul>
                    <li>(一) 餐廳預約系統之開發、銷售及營運業務等業務執行</li>
                    <li>(二) 員工之錄用、人事管理。</li>
                    <li>(三) 網路預約之使用者身分確認</li>
                    <li>(四) 本公司網路預約系統活動資格判定</li>
                    <li>
                        (五)
                        連絡網路預約系統之使用者、作成使用者統計資料、或進行關於網路行為之調查或研究，或提供相關行銷活動或客戶資訊或服務之用。
                    </li>
                    <li>(六)其他為達成上述目的而對第三人之提供或其他行為等</li>
                </ul>

                <h4 style={{ marginTop: "40px" }}>二、個人資料蒐集方式</h4>

                <p>
                    本公司將透過書面、電話、電子郵件、本公司網站、網路預約系統之登錄、由網路預約系統之登錄餐廳提供等途徑蒐集個人資料。
                </p>

                <h4 style={{ marginTop: "40px" }}>三、個人資料的儲存及保管方式</h4>

                <p>
                    個人資料均被嚴密地保存在本公司的資料庫系統中。任何人均需在本公司訂定之權限管理規範下，始得進行資料之取得與利用，非經授權之人員，均不得以任何方法取得及利用個人資料。
                </p>

                <h4 style={{ marginTop: "40px" }}>四、資料安全及保護方法</h4>

                <p>
                    本公司網站及網路預約系統，皆以 Secure Sockets Layer (SSL)
                    進行個人資料傳輸的加密，並加裝防火牆防止不法入侵，避免個人資料在傳輸過程中遭非法擷取。另外，網路預約系統設定之使用者密碼，亦將予以加密，以確保密碼之安全性。
                </p>

                <h4 style={{ marginTop: "40px" }}>五、個人資料分類、利用範圍及項目</h4>
                <p>
                    本公司所蒐集之個人資料範圍包括以下項目。但本公司可依利用目的之差異或需要，增刪所蒐集個人資料之分類與項目。
                </p>

                <p>
                    1.辨識個人者。
                    <br />
                    (A)姓名
                    <br />
                    (B)行動電話
                    <br />
                    (C)電子信箱
                    <br />
                    (D)申請相關服務之紀錄
                    <br />
                    (E)聲音(以錄音檔蒐集、處理及利用)
                    <br />
                    (F)網路預約系統之使用者 ID 和密碼
                </p>

                <p>
                    2.個人描述
                    <br />
                    (A)年齡
                    <br />
                    (B)性別
                    <br />
                    (C)出生年月日
                    <br />
                    (D)出生地
                    <br />
                    (E)國籍
                </p>

                <p>
                    3.習慣
                    <br />
                    (A)網路預約系統之使用者有無抽煙、喝酒等
                </p>

                <h4 style={{ marginTop: "40px" }}>六、個人資料利用之對象</h4>

                <p>
                    依照本隱私權政策，視同個人資料之所有人已同意本公司、關係企業及網路預約系統之登錄餐廳，得於前述範圍內蒐集、處理、保存、傳遞及使用該等資料，且除非獲資料所有人之同意或下列情況外，本公司不會將這些資料用於其他用途或揭露予前述對象以外之第三人：
                    <br />
                    (A) 法律明文之規定。
                    <br />
                    (B) 受司法機關或其他有權機關基於法定程序之要求。
                    <br />
                    (C) 為保障本網站/系統之財產及權益。
                    <br />
                    (D) 為免除資料所有人之生命、身體、自由或財產上之危險或為防止他人權益之重大危害。
                    <br />
                    (E)為增進公共利益所必要。 <br />
                    (F)公務機關或學術研究機構基於公共利益為統計或學術研究而有必要，且資料經過提供者處理後或經蒐集者依其揭露方式無從識別特定之資料所有人。{" "}
                    <br />
                    (G)有利於資料所有人權益。
                    <br />
                    (H) 其他為達成第一條所述目的而對第三人之提供或其他行為等
                    <br />
                    依照本隱私權政策，視同個人資料之所有人已同意本公司、關係企業及網路預約系統之登錄餐廳，得於(1)
                    第一條所述目的存續期間或(2)依相關法令所規定、因執行法定業務所必須之保存期間或依個別契約就資料之保存所定之保存年限或(3)本公司因執行業務所必須之保存期間(上開期間或保存年限，以最長者為準)內，於本公司進行業務或未來可能進行業務之地區(包括但不限於台灣、日本、新加坡)以第一條所述之目的使用之。
                </p>

                <h4 style={{ marginTop: "40px" }}>七、個人資料之變更修改以及相關行使權利說明</h4>

                <p>
                    非經個人資料所有人之同意或依法令之規定，本公司不會擅自修改任何個人資料。個人資料之所有人依據個人資料保護法第三條規定，得就其於本公司、網路預約系統所登錄或留存之個人資料行使下列權利：
                    <br />
                    (A) 向本公司查詢、請求閱覽或請求製給複製本，而本公司得酌收至少新台幣參百元必要成本費用。
                    <br />
                    (B) 向本公司請求補充或更正個人資料。
                    <br />
                    (C)
                    請求本公司公司停止蒐集、處理或利用個人資料。另依據個人資料保護法第十一條第二項，如個人資料正確性有爭議，原則上仍依個人資料所有人請求停止處理或利用，但若因執行業務所必須並註明其爭議或經個人資料所有人書面同意者，不在此限。
                    <br />
                    (D)
                    向本公司請求刪除個人資料。另依據個人資料保護法第十一條第三項，個人資料蒐集之特定目的（即上述『個人資料利用之目的』）消失或期限屆滿時，應依個人資料所有人請求刪除、停止處理或利用個人資料。但因執行業務所必須或經個人資料所有人書面同意者，不在此限。
                    <br />
                    (E)辦理前述(A)至(D)之相關程序如下：
                    <br />
                    ①受理申請後，本公司將以郵件寄出制式的請求書，以供填寫。
                    <br />
                    ・如為向本公司查詢、請求閱覽或請求製給複製本時，請填寫「查詢、閱覽、製給複製本請求書」
                    <br />
                    ・如為補充、更正、刪除，或請求刪除、停止處理或利用，或請求刪除時，請填寫「個人資料變更等及刪除等請求書」
                    <br />
                    ②將填好的請求書、若是代理人代為請求，則須檢附可確認代理人之證明文件，以及金額相當於手續費用的郵政匯票（只有請求查詢、請求閱覽或請求製給複製本時才需要）等，郵寄至後述之個人資料洽詢窗口。
                    <br />
                    ③本公司在收到上述請求書後，為了確認是申請人本人無誤，將會從登錄在本公司內之個人資料中，選擇大約2項足以確認是本人之資料（如電話號碼或出生年月日等）與申請人核對。
                    <br />
                    ④本公司之回覆原則上以書面方式（密封郵件）寄給申請人本人。
                    <br />
                    ⑤若為代理人代為請求，則須檢附可確認代理人之證明文件
                    <br />
                    申請人如為代理人，請於信中附上證明代理意旨之文件，以及能證明為代理人本人之文件。各資料中的戶籍地等資訊，只保留縣市，其餘部份請都塗黑。
                    <br />
                    ①證明代理意旨之文件
                    <br />
                    ＜個人資料所有人委任代理人進行相關請求時＞
                    <br />
                    個人資料所有人出具之委任狀（正本）
                    <br />
                    ＜代理人為無/限制行為能力人之法定代理人時＞下列任一之影本
                    <br />
                    戸籍謄本
                    <br />
                    其他足以確認法定代理權之官方文件
                    <br />
                    ＜代理人為受輔助宣告或監護宣告之人的輔助人或監護人時＞下列任一之影本
                    <br />
                    相關宣告之證明文件
                    <br />
                    其他足以確認輔助人監護人之官方文件
                    <br />
                    ②能證明為代理人本人之文件
                    <br />
                    駕照
                    <br />
                    護照
                    <br />
                    健保卡
                    <br />
                    國民身分證
                </p>

                <h4 style={{ marginTop: "40px" }}>八、個人資料提供注意事項</h4>

                <p>
                    基於本公司、網路預約系統所提供之各項服務，個人資料所有人同意於登錄時提供完整詳實且符合真實之個人資料。所登錄之資料事後有變更時，應隨時於線上更新之或以前述第七條之規範方式進行資料之變更修改。個人資料所有人提供之個人資料若有填寫不實，或原所登錄之資料已不符合真實而未更新，或有任何誤導之嫌，本公司保留隨時終止個人資料所有人使用本次服務資格之權利。
                    <br />
                    如果個人資料所有人提供之個人資料違反或破壞本公司網路預約系統之服務宗旨，或以其他不正方式影響本公司之業務，或與網路預約系統之登錄餐廳為惡意競爭等情事，或有發生上開情形之虞時，本公司保留隨時終止個人資料所有人登錄資格及使用本公司相關服務資格之權利。
                </p>

                <h4 style={{ marginTop: "40px" }}>九、選擇退出方式以及不提供個人資料所致權益之影響</h4>

                <p>
                    (A)如果個人資料所有人不願意將個人資料提供予本公司繼續使用者，可透過本公司所提供之聯絡方式聯繫本公司，本公司將於取得個人資料所有人的同意後即進行個人資料之刪除。
                    <br />
                    (B)當本公司協助個人資料所有人執行上述需求時，將因此刪除個人資料所有人之個人資料、登錄記錄以及已完成之活動登錄資格等資料，並於資料刪除即刻起無法進行網路預約系統之必要審核或作業程序而無法使用相關服務。若需本公司繼續提供服務，個人資料所有人需另外重新輸入個人資料並執行登錄動作，已刪除之登錄資料無法回朔，敬請見諒。
                </p>

                <h4 style={{ marginTop: "40px" }}>十、個人資料管理負責人及聯絡管道</h4>

                <p>
                    本公司之個人資料管理負責人
                    <br />
                    管理人姓名：Hanae Ikehata
                    <br />
                    所屬部門：Toreta Taiwan, Inc.
                    <br />
                    連絡方式：enquiry.tw@toreta.in
                    <br />
                    個人資料保護方針相關事宜，請洽下述窗口 。<br />
                    Toreta Taiwan, Inc.　
                    <br />
                    台北市中山區南京東路2段2號8樓
                    <br />
                    個人資料洽詢窗口
                    <br />
                    Number: (02)2567-8209 #101、#103
                    <br />
                    本方針除了將分發給所有員工、令全員知曉外，也會刊登在本公司官網首頁及宣傳手冊等，方便大眾隨時取得。
                </p>

                <p>
                    2017年4月24日訂定
                    <br />
                    2018年1月15日修正
                </p>
            </div>
        </>
    );
}
