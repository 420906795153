/**
 * 定義有哪些行為，統一觸發的類型
 */
enum Action {
    LOADING_SHOW = "LOADING_SHOW",
    LOADING_DISMISS = "LOADING_DISMISS",
    LOADING_CLEAR = "LOADING_CLEAR",
    SHOP_UPDATE = "SHOP_UPDATE",
    SHOPINFO_UPDATE = "SHOPINFO_UPDATE",
    LANG_CHANGE = "LANG_CHANGE",
}
export default Action;
