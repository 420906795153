import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Layout from "./Layout";
import { AppContext, AppContextProps } from "./Context/App";
import "./common/i18n";
import ShopPage from "./Pages/Shop";
import ReservePage from "./Pages/Reserve";
import Finish from "./Pages/Finish";
//import StaffPage from "./Pages/Staff";
import PayPage from "./Pages/Pay";
import PayFinish from "./Pages/Pay/Finish";
import Privacy from "./Pages/Privacy";
import Terms from "./Pages/Terms";
// import Test from "./Pages/Test";
import Error from "./Pages/Error";
import Fund from "./Pages/Fund";
const regex = /^\/[a-zA-Z0-9|-]+\/?$/;

function App() {
    // const [loading, setLoading] = React.useState(false);
    // const [lang, setLang] = React.useState(localLang);
    const publicKey = regex.exec(window.location.pathname) ? window.location.pathname.replaceAll("/", "") : null;
    const appContextInitValue: AppContextProps = {
        // lang,
        // setLang,
        publicKey,
        //     loading,
        //     setLoading,
    };
    // console.debug(appContextInitValue);
    return (
        <Router>
            <AppContext.Provider value={appContextInitValue}>
                <Layout>
                    <Switch>
                        <Route exact path="/reserve-info" component={() => <ReservePage />} />
                        <Route exact path="/reserve-info/finish" component={() => <Finish />} />
                        <Route exact path="/pay/:hash" component={() => <PayPage />} />
                        <Route exact path="/pay-info/finish" component={() => <PayFinish />} />
                        <Route exact path="/error/:status" component={() => <Error />}></Route>
                        <Route exact path="/privacy/:country" component={() => <Privacy />}></Route>
                        <Route exact path="/privacy" component={() => <Privacy />}></Route>
                        <Route exact path="/terms/:country" component={() => <Terms />}></Route>
                        <Route exact path="/terms" component={() => <Terms />}></Route>
                        <Route exact path="/fund/:piId" component={() => <Fund />}></Route>
                        <Route path="/" component={() => <ShopPage />} />
                    </Switch>
                </Layout>
            </AppContext.Provider>
        </Router>
    );
}

export default App;
