import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Box, Grid, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { CardNumberElement, CardCvcElement, CardExpiryElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import ToretaApi from "../../../../ToretaApi";
import { logEvent, ErrorResult } from "./utils";
// import "../styles/common.css";
//import "./stripe.css";
import { GreenButton } from "../../../../Components";
import Actions from "../../../../Actions/Actions";
import StripeInput,{ELEMENT_OPTIONS} from "./StripeInput";

const CheckoutForm = ({ initValue, clientSecret, failAction }) => {
    const toretaApi = ToretaApi();
    const { t } = useTranslation();
    const elements = useElements();
    const stripe = useStripe();
    const [name, setName] = useState("");
    const [postal/*, setPostal*/] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const { hash } = useParams();
    const finishWork = async () => { //every time checkout button clicked
        // event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        Actions.loadingControl();
        const cardElement = elements.getElement(CardNumberElement);

        //3. stripe retrieveSetupIntent with CheckoutForm's preset clientSecret
        let { setupIntent, error } = await stripe.retrieveSetupIntent(clientSecret);
        //console.log("2 CheckoutForm, stripe.retrieveSetupIntent:",setupIntent);
        
        if(error){
            //console.log("2 CheckoutForm, stripe.retrieveSetupIntent error:", error);
            setErrorMessage(t("ERROR.STRIPE_APIKEY"));
            Actions.loadingControl(false);
            return; //end render screen
        }
        if (setupIntent.status !== "succeeded") {
            const cardresult = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: name, //信用卡名字
                        email: initValue.email,//帳單email
                        address: {
                            postal_code: postal,//郵遞區號
                        },
                    },
                },
            });
            setupIntent = cardresult.setupIntent;
            error = cardresult.error;
            //console.log("3 CheckoutForm, stripe.confirmCardSetup:",setupIntent);
        }
        
        if (error) {
            // Display error.message in your UI.
            //console.log("3 CheckoutForm, stripe.confirmCardSetup error:", error);
            setErrorMessage(t("PAYMENT.ERROR.CC_ERROR"));
        } else {
            if (setupIntent.status === "succeeded") { //TODO: add more status? 保持交易資訊無誤？
                //與server交換stripe參數 clientSecret
                const response = await toretaApi.AddReserveSripe(initValue, setupIntent.payment_method, null, failAction);
                //console.log("4 CheckoutForm, AddReserveSripe response:",response);
                if (!response) {
                    //console.log("4 CheckoutForm, no response, end of payment.");
                    setErrorMessage(null);
                } else {
                    if(response.status==="CONFIRM")
                    {
                        //call stripe confirm api with clientSecret 與server交換stripe參數 paymentIntent
                        const { paymentIntent, error } = await stripe.confirmCardPayment(response.clientSecret);
                        //console.log("5 CheckoutForm, stripe.confirmCardPayment:",paymentIntent, error);
                        if (paymentIntent && (paymentIntent.status === "requires_capture" || paymentIntent.status === "succeeded")) {
                            //console.log("5 CheckoutForm, before toretaApi.PayStripeWithIntent:",hash, initValue);
                            //pay existing reservation
                            await toretaApi.PayStripeWithIntent(
                                hash
                                ,initValue
                                , paymentIntent.id //paymentIntent id (to be saved in server payment log)
                                , (reservationNo) => { //nextAction 
                                    //console.log("6 CheckoutForm, PayStripeWithIntent response:",reservationNo);
                                }
                                ,failAction);
                        } else {
                            //console.log("6 CheckoutForm error, paymentIntent.status not requires_capture or succeeded.");
                            failAction();//back to CheckoutForm failAction
                            setErrorMessage(t("PAYMENT.ERROR.CC_ERROR"));
                        }
                    }else{
                        //console.log("4 CheckoutForm error, response.status:" + response.status);
                        failAction();//back to CheckoutForm failAction
                        setErrorMessage(t("PAYMENT.ERROR.CC_ERROR"));
                    }
                }
            }
        }
        Actions.loadingControl(false);
        
    };
    return (
        <>
            <Box my={3}>
                {errorMessage && (
                    <Alert severity="error">
                        <Box fontWeight="fontWeightBold">
                            <ErrorResult>{errorMessage}</ErrorResult>
                        </Box>
                    </Alert>
                )}
                {/* {paymentMethod && <Result>Got PaymentMethod: {paymentMethod.id}</Result>} */}
            </Box>
            <Grid container spacing={2} alignContent={"center"}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label={t("NAME")}
                        value={name}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="cardNumber"
                        fullWidth
                        variant="outlined"
                        label={t("PAYMENT.CARD.NUMBER")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardNumberElement,
                                onBlur: logEvent("blur"),
                                onChange: logEvent("change"),
                                onFocus: logEvent("focus"),
                                onReady: logEvent("ready"),
                                options: ELEMENT_OPTIONS,
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="onBlur"
                        fullWidth
                        variant="outlined"
                        label={t("PAYMENT.CARD.EXPIRY")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardExpiryElement,
                                onBlur: logEvent("blur"),
                                onChange: logEvent("change"),
                                onFocus: logEvent("focus"),
                                onReady: logEvent("ready"),
                                options: ELEMENT_OPTIONS,
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="cvc"
                        fullWidth
                        variant="outlined"
                        label={t("PAYMENT.CARD.CVC")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardCvcElement,
                                onBlur: logEvent("blur"),
                                onChange: logEvent("change"),
                                onFocus: logEvent("focus"),
                                onReady: logEvent("ready"),
                                options: ELEMENT_OPTIONS,
                            },
                        }}
                    />
                </Grid>
            </Grid>
            <Box textAlign="center" mt={5} mb={2}>
                <GreenButton disabled={!stripe} variant="contained" color="primary" onClick={finishWork}>
                    {t("HOME.BUTTON_PAY_CONFIRM")}
                </GreenButton>
            </Box>
        </>
    );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const App = ({ initValue }) => {
    const toretaApi = ToretaApi();
    const [stripePromise, setStripePromise] = useState(null);
    const [data, setData] = useState({ clientSecret: "", id: ""});
    const refreshStripe = () => {
        toretaApi.GetStripePK(initValue).then((data) => {
            const newStripe = loadStripe(data.PK);
            setData(data);
            setStripePromise(newStripe);
        });
    };
    React.useEffect(() => {
        refreshStripe();
        // eslint-disable-next-line
    }, [toretaApi.publicKey]);
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm initValue={initValue} clientSecret={data.clientSecret} failAction={refreshStripe} />
        </Elements>
    );
};

export default App;
