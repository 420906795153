import React from "react";
 

export default function SGPrivacy() {
    return (
        <>
            <div className="etcContent">
                <p>
                    Toreta Taiwan,
                    Inc.(以下簡稱「本公司」）係跟據台灣法律設立，主營業所位於台北市中山區南京東路2段2號8樓。
                </p>

                <p>
                    今因本公司欲將其設計開發之餐飲業預約及消費者管理系統「Toreta」以及相關服務（以下簡稱「本服務」）提供予系統使用者，為明確雙方就本服務使用時相關之權利義務，爰制訂本服務使用條款（以下簡稱「本條款」），供本公司及系統使用者共同遵守。
                    <br />
                    開始使用本服務前，本公司與系統使用者須簽訂規範有效期限及費用等其他必要事項之申請書（以下簡稱「申請書」）。經雙方簽署之申請書及本服務使用條款之內容，共同構成本公司及系統使用者間之個別使用授權契約（以下合稱「授權契約」）。
                </p>

                <h3>一、「 Toreta 」系統帳號</h3>

                <p>
                    系統使用者須先設定於「 Toreta
                    」系統之專用帳號及密碼，始能利用本服務。專用帳號係由系統使用者自行設定，並經本公司認可後發行。
                    <br />
                    專用帳號和密碼請妥善保管，除為使用本服務而有必要者外，勿告知無關之第三人。
                    <br />
                    於專用帳號內或透過專用帳號之行為，應由系統使用者自負其責。
                    <br />
                    專用帳號之密碼，請勿與使用於其他服務或應用軟體上之密碼重複。
                    <br />
                    因可歸責於系統使用者之事由，致專用帳號外洩、遭盜用或有其他情事而生之損失或損害，本公司概不負責。
                    <br />
                    若系統使用者發現專用帳號有遭非法使用之情事時，應立即變更密碼，並通知本公司。
                </p>

                <h3>二、本服務之使用</h3>

                <p>
                    系統使用者已理解並同意，除系統使用者與本公司另有約定外，為了接受本公司提供本服務，必須已自己之費用及責任自行準備如下事項：
                    <br />
                    （1）iPad 等終端裝置
                    <br />
                    （2）可接受本服務之通訊線路等通訊環境
                    <br />
                    （3）其他相關之準備工作
                    <br />
                    惟若系統使用者不便自行準備iPad 等終端裝置，亦可向本公司承租。
                </p>

                <p>
                    此外，除雙方另有約定外，上述作業環境之建置，以及後續之維修作業費用與相關責任，概由系統使用者自行負責。
                    <br />
                    使用本服務時，嚴禁系統使用者有下列行為：
                    <br />
                    （1）
                    妨礙本服務之運作，或是以本公司提供之應用軟體（以下簡稱「本應用軟體」）及規定順序以外之方法存取本服務。
                    <br />
                    （2） 身為本公司之競爭業者，卻開設或使用專用帳號。
                    <br />
                    （3） 將本服務之專用帳號與密碼供本公司之競爭業者，或其他第三人使用。
                    <br />
                    （4） 違反法令之行為。
                    <br />
                    （5） 侵害智慧財産權之行為。
                    <br />
                    除上述外，有如下任一情形時，並經本公司判斷為必要時，得暫停或停止對系統使用者提供本服務。
                    <br />
                    （1）系統使用者違反本服務使用條款，或其他與本公司締結之約定或政策時，
                    <br />
                    （2）本公司針對可疑之不正當行為進行調査，判斷系統使用者有不正當或被禁止之行為時。
                </p>

                <h3>三、系統月租費暨服務期間</h3>

                <p>
                    系統使用者應依其於申請書中選擇之服務方案，按月支付本公司系統月租費及簡訊、 FAX
                    通訊傳輸費等，作為使用本服務之對價。
                    <br />
                    系統使用者應於申請書中記載之繳費期限內，將系統月租費及簡訊、 FAX
                    通訊傳輸費等依本公司指定之方法支付。
                    <br />
                    上述費用之匯款手續費及其他支付款項時所需之費用，由系統使用者負擔。
                    <br />
                    系統使用者若逾期未繳上述費用，其應支付本公司之費用，於各該費用到期日之翌日起，以週年利率 14.6%
                    按日加計遲延利息。
                    <br />
                    授權契約之有效期限於雙方簽署之申請書另行約定。
                </p>

                <h3>四、「 Toreta 」系統及本服務之變更</h3>

                <p>
                    為維持「 Toreta 」系統之正常運作或修改其功能，本公司有權變更本服務之部份內容，或實施相關新方案。
                    <br />
                    系統使用者同意並理解，因本公司實施前項之更新，而使本服務在特定期間內功能受限、僅能以特定方法存取，或暫時無法使用本服務時，系統使用者承諾不因此而向本公司為任何主張或請求，本公司亦不就此對系統使用者負任何責任或義務。
                    <br />
                    本公司進行「 Toreta
                    」系統之更新，或就本服務內容之全部或部分有變更、停止或廢除時，應事前通知系統使用者。
                </p>

                <h3>五、以促進系統使用者集客為目的之資訊傳送</h3>

                <p>為了促進系統使用者招攬客人，本公司得以自有之媒體進行傳輸及處理利用系統使用者之訊息。</p>

                <h3>六、分析等</h3>

                <p>
                    系統使用者同意本公司以提升本服務品質為目的來取得系統使用者使用本應用軟體之資料，以及利用第三人開發的調査工具進行統計數據的分析。
                </p>

                <h3>七、故障報告之傳輸</h3>

                <p>系統使用者同意本應用軟體發生狀況時，陳述故障內容之故障報告會自動傳輸給本公司。</p>

                <h3>八、消費者資料</h3>

                <p>
                    系統使用者使用本服務過程中所蒐集之消費者預約內容及其他個人資料，將透過網路國際傳輸至本公司之「
                    Toreta 」系統主機中儲存，系統使用者理解並承認其為相關個人資料之取得主體。
                    <br />
                    為了改善或提升「 Toreta
                    」系統及本服務之功能，本公司可能會在加工至無法特定個人之前提下，使用系統使用者依前項約定儲存於「
                    Toreta 」系統主機消費者個人資料或其他資料。
                    <br />
                    系統使用者在使用「 Toreta
                    」系統及本服務蒐集、處理或利用消費者之個人資料時，應遵循個人資料保護法及其他相關法令，以及本公司頒布之隱私權政策及相關之個資保護措施。
                </p>

                <h3>九、排除反社會勢力</h3>

                <p>
                    系統使用者保證目前公司內部所有成員五年內皆無黑道、不良幫派成員、激進團體或其他類似團體或成員之經歷。且無以犯罪為宗旨或以其成員從事犯罪活動，具有集團性、常習性及脅迫性或暴力性之組織成員之背景(以下統稱為反社會勢力成員)。系統使用者承諾並聲明不觸及以下各項所標示之內容。
                    <br />
                    (1)反社會勢力成員支配公司之經營。
                    <br />
                    (2)公司之經營與反社會勢力成員有實質上之來往關係。
                    <br />
                    (3)公司因圖謀第三方之不當利益或以傷害第三方為目的而不當利用反社會勢力成員之相關行為。
                    <br />
                    (4)公司提供反社會勢力成員資金援助或物資援助暴力團員之情況。
                    <br />
                    (5)公司、公司內部成員或經營關係者符合上述反社會勢力成員之情況發生。
                    <br />
                    系統使用者保證對於本公司或是第三方企業不實施下列行為。
                    <br />
                    (1)意圖為自己或第三人不法之所有，以恐嚇、脅迫或其他違反其意願之方法使人將本人或第三人之物交付者。
                    <br />
                    (2)超過法律規範所容許之不當行為。
                    <br />
                    (3)以言語脅迫或暴力行為對待第三人。
                    <br />
                    (4)意圖散布於眾，而指摘或傳述足以毀損他人名譽之事或以上述行為妨害第三方之正常業務行為者。
                    <br />
                    (5)與前述各項類似之行為。
                    <br />
                    若本公司發現系統使用者與符合第一項所列之行為者(以下稱『反社會勢力關係者』)有交易或合作關係時，得要求系統使用者儘速解除與反社會勢力關係者之間的交易或合作關係。且系統使用者應承諾上述狀況發生時，應盡力盡快終止與反社會勢力關係者之間的交易或合作關係。
                    <br />
                    若本公司發現系統使用者針對第一項所宣示之內容與事實不符時，又或者違反上述第一項到第三項之條款時，可不經催告改善，逕行終止本服務及授權契約。
                    <br />
                    若基於前項所述之事由而導致本公司行使契約終止之權利時，本公司無需負擔任何損害賠償責任，並可就因契約終止所產生的各項損害及因此而衍生之損害，向系統使用者行使請求損害賠償之權利。
                </p>

                <h3>十、本服務之停止等</h3>

                <p>
                    符合下述任一情況者，本公司得於事先通知系統使用者後（若為緊急情況時，則無需事前通知），終止或暫停提供系統使用者本服務之全部或部份。
                    <br />
                    （1）進行電腦系統之檢查或維修作業時。
                    <br />
                    （2） 電腦系統或通訊線路等因事故而停止時。
                    <br />
                    （3） 因火災、停電、或天災等其他不可抗力之因素造成本服務無法營運時。
                    <br />
                    （4） 其他本公司判斷有必要終止或暫停時。
                    <br />
                    系統使用者同意本公司根據前項各款採取終止或暫停本服務之措施造成系統使用者產生損害時，本公司無須負擔損害賠償及回復原狀責任。
                </p>

                <h3>十一、保證之排除</h3>

                <p>
                    除因本服務使用條款之約定或法令之規定而終止或暫停本服務外，本公司將以善良管理人之注意義務，盡力維持本服務正常且持續運作。
                    <br />
                    本公司將盡力維護「Toreta」系統及本服務之內容完善，但無法保證「 Toreta
                    」系統、本應用軟體及本服務中毫無任何瑕疵。
                    <br />
                    本公司將以善良管理人之注意義務，盡力將本服務使用期間內系統使用者傳輸之消費者預約内容及其他個人資料加以安全儲存。但本公司無法保證相關資料永久存在或不因任何原因毀損滅失。
                    <br />
                    前項相關資料之備份等維護措施及費用，仍應由系統使用者自行負責。
                    <br />
                    本服務係以授權契約締結當時之科技現況提供，系統使用者同意並理解本公司無法對其就「 Toreta
                    」系統及本服務之內容做出任何超過本條款約定範圍外之任何承諾或保證。
                    <br />
                    系統使用者應自行負責遵循相關法令或規範之費用及風險，其對於本服務之使用或過程，如有違反系統使用者適用之法令或業界團體之內部規範等，本公司對此概不負責。
                </p>

                <h3>十二、責任之限制</h3>

                <p>
                    系統使用者因使用「Toreta」系統及本服務所蒙受之損失或損害，除因本公司之故意或重大過失所致者外，本公司不負賠償責任。
                    <br />
                    雖有前項規定，如本公司依法或依約仍需負擔對系統使用者之損害賠償責任時，本公司之賠償範圍僅限於直接損害，不含所失利益、商機錯失等任何間接損害。且本公司負擔之損害賠償金額，應以其在過去3個月內實際向系統使用者收取之費用總額為上限。
                </p>

                <h3>十三、授權契約之終止</h3>

                <p>
                    系統使用者發生下述任一款事由時，本公司得以書面通知逕行終止授權契約。
                    <br />
                    （1）違反本服務使用條款、申請書或授權契約之任何約定，在接獲本公司要求改正通知之 15 日後仍未改正時。
                    <br />
                    （2）陷於停止付款或無法付款時，或票據未為兌現，或遭金融機關為停止交易處分，或稅金滯納遭處分時，或公司負責人有背信情事時。
                    <br />
                    （3）財產受第三人聲請假扣押、假處分等債權保全執行或其他終局強制執行時。
                    <br />
                    （4）受破產、解散、清算、公司重整程序開始之聲請，或自行聲請前述程序時。
                    <br />
                    （5）公司決定停業或有重大變更，或決議解散時。
                    <br />
                    （6）將全部或重要的部分資產讓渡或出租予第三人，資本有重大變更，或其他併購情事時。
                    <br />
                    （7）受主管機關停止營業、或撤銷營業執照、營業登記等處分時。
                    <br />
                    （8）資産或信用狀況惡化，或有足夠之理由被認為情況堪虞時。
                    <br />
                    （9）經證實董事、監察人、員工或其他成員、股東、來往廠商、顧問或其他諮詢單位人士為反社會勢力等，或與之有任何形式之交流或關連時，在接獲要求斷絕關係之通知後，未立即斷絕者，或提供資金予洗錢相關犯罪者。
                    <br />
                    （10）系統使用者允許從事與本公司為競業關係之第三人使用本服務時。
                    <br />
                    （11）本公司為營運「 Toreta 」系統及本服務所必要之授權，遭第三人終止、解除或停權時。
                    <br />
                    （12）有其他本公司判斷為難以繼續履行授權契約之重大事項。
                </p>

                <h3>十四、本應用軟體之智慧財產權</h3>

                <p>
                    本服務因前條第 2 項第 2
                    款之事由而無法使用而致生擔對系統使用者或第三人損失或損害時，本公司不負賠償之責任。
                    <br />
                    本公司基於本服務使用條款提供本應用軟體給系統使用者，除有特別約定外，並無移轉有關本應用軟體智慧財產權，亦無將有關本應用軟體中使用之所有品牌或商標之使用權利授予系統使用者之意思。系統使用者不得有複製或改變有關本應用軟體程式碼之情事、亦不得有侵害本公司、或授予其相關智慧財產權之第三人之行為。
                </p>

                <h3>十五、本應用軟體相關糾紛處理等</h3>

                <p>
                    系統使用者因有關本應用軟體之瑕疵或權利關係等而受到第三人申訴、請求損害賠償或其他請求或主張時，應立即通知本公司，並遵照本公司之指示處理之。
                    <br />
                    系統使用者因有關本應用軟體之使用而受到第三人申訴、請求損害賠償或其他請求或主張時，一切費用均應由系統使用者自行負責並自行解決問題。
                    <br />
                    本公司如因前項之請求或主張而蒙受損害（包括但不限於律師費、第三者請求之賠償金額等）及損失時，應由系統使用者負擔之。
                </p>

                <h3>十六、不可抗力</h3>

                <p>
                    在不可抗力之狀況下（包括但不限於火災、停電、駭客入侵、電腦中毒、地震、洪水、戰爭、流行病、停止通商、罷工、暴動、無法確保物資及運輸設備、政府當局之介入等），使得本公司就本服務使用條款中之義務履行延遲時，對系統使用者無須負債務不履行之責。
                </p>

                <h3>十七、保密</h3>

                <p>
                    本授權契約中所謂的「祕密資訊」，是指因本授權契約，系統使用者從本公司處以口頭、書面或其他記錄媒體等提供或揭露，或是以某種方法得知的
                    Toreta
                    之技術、營業、業務、財務、組織及與其有關或標註為秘密之一切資訊，但下列各項排除在祕密資料之外。
                    <br />
                    （1）從本公司提供或揭露、得知時，已為一般公知者。
                    <br />
                    （2）從本公司提供或揭露、得知後，因刊物或其他媒體等而為公知者。
                    <br />
                    （3）從擁有提供或揭露權限之第三人處合法取得，且該第三人對本公司無須負起保密義務者。
                    <br />
                    （4）系統使用者獨自開發者。
                    <br />
                    （5）經本公司以書面確認無保密之必要者。
                </p>

                <p>
                    系統使用者不得將祕密資訊使用於本授權契約之目的外，未經本公司事前書面同意，亦不得以任何方式，將本公司的祕密資訊提供、揭露或洩漏予第三人。但系統使用者係基於法令、法院或行政機關之命令、要求或請求而交付或揭露本公司之祕密資訊時，不在此限。
                    <br />
                    若系統使用者遭受前項之命令、要求或請求時，應立即通知本公司，並於必要之最小限度內交付或揭露本公司之祕密資訊。
                    <br />
                    本服務期間屆至或因故終止時，或本公司要求時，系統使用者應立即遵照本公司之指示，將祕密資訊及其任何形式之備份歸還本公司或銷毀之。
                </p>

                <h3>十八、通　知</h3>

                <p>
                    依本條款所為之通知，係指以面交、掛號或電子郵件之方式，對申請書中記載之他方住址或電子郵件信箱所做之書面意思表示。
                    <br />
                    任一方得依據前項規定通知他方，以變更申請書中記載之通知對象、地址及電子郵件信箱等資訊。
                    <br />
                    系統使用者未依前項變更聯絡資訊，或因可歸責於己之事由致未收受本公司之通知時，以本公司發出通知之日起算之
                    14 天後，視為該通知已到達。
                </p>

                <h3>十九、條款內容之變更</h3>

                <p>
                    本公司得自由變更本服務使用條款內容。本公司變更本服務使用條款時，會儘速將變更內容通知系統使用者。系統使用者於接獲變更內容之通知後繼續使用本服務，或未在本公司規定之期限內提出異議的話，視同系統使用者同意本服務使用條款之變更。
                </p>

                <h3>二十、禁止轉讓等</h3>

                <p>
                    未經本公司之事前書面同意，系統使用者不得將授權契約之地位，或依授權契約規定之權利與義務，轉讓、設定擔保與第三人，或為其他任何處分。
                    <br />
                    本公司將本服務及與之有關之事業轉讓(包括但不限於合併分割或其他事業經營權移轉之情形)予第三人時，授權契約上之地位及依授權契約規定之權利與義務、系統使用者之登錄資料和其他消費者資料，均可因其事業轉讓而隨之轉讓給該事業之受讓人。
                    <br />
                    針對前項之轉讓，視為系統使用者已於簽署授權契約時事前同意。
                </p>

                <h3>二十一、條文個別有效性</h3>

                <p>
                    即使本服務使用條款之任一條文項目或其部份被判斷為無效或無法執行時，本服務使用條款之其餘規定及被判斷為無效或無法執行之規定的其餘部份，依然擁有完全之效力。
                    <br />
                    如有前項情形，雙方當事人應盡力將無效或無法執行之條文修改為合法且可行。
                </p>

                <h3>二十二、準據法及司法管轄權</h3>

                <p>
                    授權契約準據中華民國法令，如因授權契約涉訟，雙方同意以台灣台北地方法院作為第一審之專屬合意管轄法院。
                </p>

                <h3>二十三、協　議</h3>

                <p>就授權契約未定之事項及解釋疑義，除依循準據法令之規定及習慣外，雙方當事人應秉持誠意協商解決。</p>

                <h3>二十四、關於網路預約系統之特別規定</h3>

                <p>
                    網路預約系統係指本公司提供之預約系統。除上述條文外，網路預約系統亦適用以下規定。
                    <br />
                    網路預約系統由本公司提供給消費者，消費者使用本系統向系統使用者預約時，消費者與系統使用者間根據預約內容之契約便成立。因該契約之成立、取消或變更等而產生之問題，皆應由系統使用者與消費者間自行解決，概與本公司無涉。
                    <br />
                    消費者透過網路預約系統向系統使用者預約後，如要取消或變更預約內容，可透過本系統或直接連絡系統使用者辦理取消或變更手續，相關程序及條件皆由系統使用者負責訂定，概與本公司無涉。
                    <br />
                    網路預約系統內登錄的資料如要變更時，應由系統使用者負責變更，因該變更之延遲而產生之損害，應由系統使用者負責賠償，本公司概不負責。
                    <br />
                    消費者透過網路預約系統預約時，相關個人資料係由本公司直接蒐集處理及利用
                    <br />
                    本公司將依據本公司與消費者間之使用條款、隱私權政策、個人資料保護法及其他相關法令，妥善處理及保護消費者之個人資料。
                    <br />
                    於前項情形，系統使用者亦應依據個人資料保護法及其他相關法令，合法且妥善處理及利用消費者之個人資料，並盡個人資料保護法及其他相關法令上規定之告知等義務。
                </p>

                <p>
                    2017年4月24日訂定
                    <br />
                    2018年1月15日修正
                </p>
            </div>
        </>
    );
}
