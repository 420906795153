import React from "react";
import { /*NavLink,*/ useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

type RouteParams = {
    status: undefined | string;
};
export default function RecipeReviewCard() {
    const { t } = useTranslation();
    const { status } = useParams<RouteParams>();

    return (
        <>
            <Box mt={1}>
                <Alert severity="error">
                    <Box fontWeight="fontWeightBold">{t(`ERROR.${status ? status : ""}`)}</Box>
                </Alert>
            </Box>
        </>
    );
}
